define('webapp/routes/account/jobs/index', ['exports', 'webapp/mixins/list-route', 'webapp/utils/tree-utils'], function (exports, _listRoute, _treeUtils) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _listRoute.default.extend({
    itemStorage: Ember.computed.alias('myStorage.jobAdmin'),
    modelName: 'Job',

    model: function model() {
      var _this = this;

      var allCategories = this.get('allCategories');
      var itemStorage = this.get('itemStorage');
      var userId = this.get('userProxy._id');

      if (!userId) {
        throw new Error('no userId');
      }

      return itemStorage.ajaxPost({ param: 'search', data: { perPage: 9999, onlyOwned: true } }).then(function (data) {
        var items = data.model;
        return items.map(function (item) {
          item.categories = _this._populateCategories(allCategories, item.categories);
          return item;
        });
      });
    },
    afterModel: function afterModel() {
      var _this2 = this;

      var myStorage = this.get('myStorage');

      return myStorage.entry.ajaxGet({
        param: 'templatejobs'
      }).then(function (res) {
        _this2.controllerFor(_this2.routeName).set('templateJobs', res.hits);
      });
    },


    actions: {
      duplicateTemplate: function duplicateTemplate(templateJob) {
        var _this3 = this;

        var itemStorage = this.get('itemStorage');
        var jobId = templateJob._id;

        var allCategories = this.get('allCategories');

        var templateCategory = (0, _treeUtils.findInTree)(allCategories, function (cat) {
          return cat.slug === 'template' && cat.type === 'job';
        });

        var categories = templateJob.categories;
        if (templateCategory) {
          categories = categories.filter(function (catId) {
            return catId !== templateCategory._id;
          });
        }

        return itemStorage.ajaxPost({
          param: 'duplicate/' + jobId,
          data: { categories: categories },
          query: { useSlugId: true, keepTitle: true, forcePermission: true }
        }).then(function (newJob) {
          _this3.transitionTo('account.jobs.edit', newJob._id);
        });
      }
    }
  });
});