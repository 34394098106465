define('webapp/locales/en/translations', ['exports', 'webapp/locales/en/translations-base'], function (exports, _translationsBase) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var _extends = Object.assign || function (target) {
    for (var i = 1; i < arguments.length; i++) {
      var source = arguments[i];

      for (var key in source) {
        if (Object.prototype.hasOwnProperty.call(source, key)) {
          target[key] = source[key];
        }
      }
    }

    return target;
  };

  exports.default = _extends({}, _translationsBase.default, {
    'account.ui.entryBannerImageNew': 'Assign a main image for Your studio.',
    "application.ui.back": "back",
    'application.ui.confirmDeleteEntry': 'Delete studio {{title}} ?',
    'application.ui.entriesFocus': 'Selected studios in focus',
    'application.ui.entriesLatest': 'latest studios in focus',
    'application.ui.goToEntry': 'Open studio on website',
    'application.ui.newEntry': 'Create new studio',
    'application.ui.noEntry': 'You have no studio. Add a new studio now...',
    'application.ui.noEntryProfile': 'has no studio.',
    'application.ui.addMedia': 'Add another image or video here',
    'application.ui.addDocument': 'Add another document here',
    'application.ui.showAllEntries': 'Show all studios',
    'application.widgets.newestEntries': 'New studios',
    'application.widgets.themeSwitch.active': 'Thanks!',
    'application.widgets.themeSwitch.default': 'Save energy with dark mode.',
    'buttons.alreadyMeToo': 'You like this studio.',
    'buttons.alreadyMeTooMobile': 'liked',
    'entries.edit': 'Edit Studio <strong>{{title}}</strong>',
    'entries.entries': 'Studios',
    'entries.entry.tooltip.alreadyCopied': 'already liked',
    'entries.entry.tooltip.alreadyFollow': 'following',
    'entries.entry.tooltip.alreadyLiked': 'already liked',
    'entries.entry.tooltip.copied': 'You like this studio, your profile has been added.',
    'entries.entry.tooltip.follow': 'Click here to follow',
    'entries.entry.tooltip.like': 'Click here to like',
    'entries.entry.tooltip.noFollow': 'You cannot follow this studio',
    'entries.entry.tooltip.noFollowNotLoggedIn': 'You must login to follow this studio',
    'entries.entry.tooltip.noLike': 'You can only like other studios',
    'entries.entry.ui.access': 'Visible only for ',
    'entries.entry.ui.addEntry': 'Add studio',
    'entries.entry.ui.allAccess': 'Everyone can see this studio',
    'entries.entry.ui.categories': 'Studio categories',
    'entries.entry.ui.commercialTitle': 'Studio company information',
    'entries.entry.ui.editEntry': 'Edit studio',
    'entries.entry.ui.editEntryNew': 'Add studio',
    'entries.entry.ui.isMainImageAndListImage': 'This image is the studio main image',
    'entries.entry.ui.isMainImageInfo': 'The section shown corresponds approximately to the desktop display, the yellow frame to the mobile display',
    'entries.entry.ui.noDescriptions': 'Missing studio despription',
    'entries.entry.ui.noEntries': 'No studios found',
    'entries.entry.ui.onlyVisibleForCustomers': 'This studio is only visible for registered users',
    'entries.entry.ui.overallProgress': 'This studio is {{value}}% complete',
    'entries.entry.ui.skillsNeeded': 'I could use ...',
    'entries.entry.ui.title': 'Studio is visible with the following name',
    'entries.entry.ui.titleError': 'You need a studio name in order to save',
    'entries.entry.ui.visible': 'This Studio is visible',
    'entries.open': 'Open studio on website',
    'entries.person.ui.open': 'Go to this persons studio',
    'entries.progress.title': 'How to improve Your studio',
    'entries.tabbar.entry': 'Studio information',
    'entries.tabbar.tooltip.entryNotSavedDetail': 'You can only save this studio with a studio title',
    'entries.tabbar.tooltip.entryNotSavedLayout': 'You can only edit the layout of this studio with a studio title',
    'entries.ui.groupDetailName': 'Name for group entries',
    'entryTypes.entry': 'Studio',
    'profile.entries.copycats': 'Studios similar to those of {{userName}}',
    'profile.entries.donates': 'Studios supported by {{userName}}',
    'profile.entries.entries': 'Studios of {{userName}}',
    'profile.entries.follows': 'Studios followed by {{userName}}',
    'profile.entries.joins': 'Studios with membership of {{userName}}',
    'profile.marketplace.offer.relatedEntry': 'Assigned studio',
    'social.hasLiked': 'You already like this studio',
    'user.company.copyContact': 'Assign company contact data to this studio',
    'user.ui.addedStudios': 'are assigned.',
    'user.ui.ownDocuments': 'Your studios documents',
    'user.ui.accountMenuEntries': 'My Studios',
    'user.ui.accountMenuEntry': 'Studio',
    'user.ui.accountMenuEntryPre': 'Studios, ',
    'user.ui.accountMenuImages': 'My Storage',
    'user.ui.accountMenuFollows': 'Studios I follow',
    'user.ui.accountMenuLikes': 'Studios I like',
    'user.ui.accountMenuSupport': 'Supported Studios',
    'user.ui.accountMenuSupports': 'Supported Studios',
    'user.ui.documents': 'Bodystreet Documents',
    'entries.entry.ui.imageType.standard': 'Standard (max. 2 images)',
    'entries.entry.ui.imageType.hero': 'Hero (banner image)',
    'entries.entry.ui.imageType.product': 'Studio image',
    'entries.entry.ui.imageType.cover': 'Cover image',
    'entries.entry.ui.imageType.team': 'Team or person image',
    'entries.entry.ui.imageType.award': 'Awards',
    'entries.entry.ui.imageType.action': 'Campaign image',
    'entries.entry.ui.imageType.testimonial': 'Testimonial',
    'entries.entry.ui.imagesInfo': "You can edit images in \"My Storage\".",
    'user.ui.accountProgressbarEntry': 'Your Studio is {{value}}% complete.',
    'user.ui.user': 'Profile',
    'user.ui.user.complete': 'Your profile is complete.',
    'user.ui.user.incomplete': 'Your user profile is still not complete. Please fill in the following fields:',
    'user.ui.user.verified': 'Your user account is verified.',
    'user.ui.salutation': 'Salutation',
    'user.ui.welcome': 'Welcome, ',
    'user.ui.hasAdministrativeRights': 'You have administrative access to invite additional users.',
    'user.company.addUser': 'Add users with access to',
    'user.company.addUserShort': 'Add user',
    'user.company.inviteMail': 'Send invite mail',
    'user.company.type.manager': 'Manager/Owner',
    'user.ui.companyNameTooltip': 'Important: Your Studio is assigned to this company name.',
    'user.ui.dataDelete': ' used by other studios. Delete it anyway? Assigned images are replaced by placeholder.',
    'user.ui.mobileNumber': 'Mobile phone',
    'user.ui.ownVideos': 'Videos',
    'user.user': 'User',
    'user.users': 'Users',
    'user.company.noEditingRights': 'You have no assigned permissions to edit.',
    'user.company.contactFor': 'Contact information for ',
    'user.company.additionalWebsite': 'Additional website URL (without https://)',
    'user.company.youtube': 'Youtube account (account name only)',
    'user.company.xing': 'Xing account (account name only)',
    'user.company.linkedin': 'LinkedIn account (account name only)',
    'search.searchBarPlaceholderEntries': "Search for studio title or address. ",
    'search.searchBarPlaceholderJobs': "Search for jobs or in job descriptions. ",
    'search.searchBarPlaceholderEvents': "Search for events. ",
    'search.searchBarPlaceholderDefault': "Search... ",
    'search.searchBarPlaceholderGeoSearch': 'Your location...',
    'search.backToResults': 'Back to results',
    'search.searchTerm.pre': 'Studios filtered by ',
    'noResults': 'No results',
    'application.ui.email': 'email',
    'application.ui.flyout.receiver': 'Contact',
    'application.ui.flyout.bodystreet-trial': 'Trial session',
    'application.ui.flyout.cart': 'Shop',
    'application.ui.flyout.bodystreet-studio': 'Studios',
    'application.ui.flyout.bodystreet-shop': 'eStore',
    'application.ui.flyout.info': 'Info',
    'application.ui.flyout.facebook': 'Facebook',
    'application.ui.flyout.sing': 'Xing',
    'application.ui.flyout.bodystreet-ems': 'Trial session',
    'application.ui.flyout.bodystreet-info': 'Info',
    'application.ui.flyout.bodystreet-infopackage': 'Infopackage',
    'entries.entry.ui.externals': 'Connectivity',
    'entries.entry.ui.tabStudio': 'Studio',
    'entries.entry.ui.toDetail': 'Go to ',
    'entries.entry.ui.facebookPixel': 'Facebook Pixel code for your studio',
    'entries.entry.ui.magicline': 'My studio software is configured for API access.',
    'entries.entry.ui.bookTrial': 'Book your trial session now',
    'entries.entry.ui.goTrialSession': 'Booking',
    'entries.entry.ui.trialSession': 'Trial session',
    'entries.entry.ui.getDirections': 'Get directions',
    'entries.entry.ui.team': 'Our Team',
    'entries.entry.ui.jobs': 'Job opportunities',
    'entries.entry.ui.articles': 'Interesting news',
    'entries.entry.ui.events': 'Events',
    'entries.entry.ui.jobApply': 'Apply now',
    'entries.entry.ui.studio': 'Our Studio',
    'entries.entry.ui.sharing': 'Share this page on {{sharing}}',
    'entries.entry.ui.tabRelations': 'Relations',
    'entries.entry.ui.shareMail': 'Share this page by mail',
    'entries.entry.fields.language': 'Select the language your entry is in',
    'entries.entry.ui.descriptionInfo': 'Tip: On selections or marked words a style editor pops up.',
    'entries.entry.ui.closed': 'closed',
    'entries.entry.ui.trialBooking': 'Book your trial session',
    'job.application.title': 'Your application',
    'job.application.thanks.salutation': 'Dear Applicant,',
    'job.application.thanks.title': 'Thank you for your application and your interest in taking your next career steps with Bodystreet.',
    'job.application.thanks.content': 'we will process your documents and then get in touch with you.',
    'job.application.thanks.contact': 'You already have questions? Contact us.',
    'job.application.thanks.successWish': 'We wish you every success.',
    'job.application.studio': 'Studio',
    'job.application.selectRegionCategory': 'Choose a region to apply for',
    'job.application.personalData': 'Your personal data',
    'job.application.selectStudio': 'Please select a location',
    'job.application.checkTransfer': 'I agree to the transfer of my application to other Bodystreet studios in the area',
    'job.application.privacyTitle': 'I confirm that I have read the "Privacy information for applicants" and agree that my personal data',
    'job.application.privacyInfo': 'My personal data will not be used for any other purpose or passed on to third parties. I can revoke this data protection consent at any time with effect for the future.',
    'job.application.checkPrivacy': 'will be stored and used to contact me in writing, by e-mail or by telephone as part of the application process and also to inform me about other job offers of Bodystreet GmbH, Bodystreet Trainings GmbH or Bodystreet franchise partners;',
    'job.application.checkApplicationPool': 'will be stored for a maximum period of 6 months as part of the inclusion in the Bodystreet applicant pool, transmitted to Bodystreet franchise partners during this period and stored and used by them to contact me in writing, by e-mail or by telephone as part of the application process and also to inform me about further job offers in the Bodystreet system.',
    'job.application.submit': 'Submit your application',
    'job.application.submitNotReady': 'Please supply all mandatory fields and files',
    'job.application.coverNote': 'Cover note',
    'job.application.cv': 'Curriculum Vitae',
    'job.application.photo': 'Photo',
    'job.application.certificate': 'Certificates',
    'job.application.misc': 'Miscellaneous',
    'job.ui.noOffer': 'No vacancies for this job at the moment.',
    'user.ui.phoneType': 'Phone',
    'user.ui.phoneTypeMobile': 'Mobile',
    'user.ui.phoneTypeLandline': 'Phone',
    'user.company.imprintFields': 'Responsible Company/Person for Imprint',
    'user.company.imprintCompany': 'Page Owner',
    'user.company.imprintCEO': 'CEO',
    'user.company.imprintTaxId': 'Tax ID',
    'user.company.imprintAddress': 'Address',
    'user.company.imprintResponsible': 'Responsible for the content',
    'trial.calendar.today': 'Today',
    'trial.calendar.form.privacy': 'I am aware, that my data will be processed for the purpose of handling my enquiry and may also be used to contact me by telephone or e-mail. I can find more information in privacy policy.',
    'trial.calendar.form.yourTrailSession': 'Your trial session on ',
    'trial.calendar.form.selectTrailSession': 'Choose your trial training date first.',
    'trial.calendar.form.info': 'At Bodystreet, you will always be supervised by a personal trainer during your trial training - and of course beyond.',
    'trial.calendar.form.submit': 'Book your trial session',
    'trial.calendar.form.trialSessionBooked': 'Your request has been received. You should receive a confirmation email shortly.',
    'trial.ptminder.title': 'Sign in or sign up with our Studio account',
    'trial.ptminder.intro': 'After you have signed in, you can schedule your trial session.',
    'menu.company.alternativeTitle': 'Values',

    // auto translated
    "user.ui.noCompany": "No company has been created yet for your user account. Contact your contact person.",
    "search.searchBarPlaceholder.job": "Search for a job title or in its description and press Enter...",
    "search.searchBarPlaceholderMobile.job": "Search for job...",
    "search.searchBarPlaceholder.event": "Search for an event title or in its description and then press Enter...",
    "search.searchBarPlaceholder.entry": "Search for studios or in their description and press Enter...",
    'search.searchBarPlaceholderArticles': 'Search in all articles…',
    'search.searchBarPlaceholderArticlesMobile': 'Search in articles…',
    "application.ui.flyout.bodystreet-voucher": "Coupon",
    "entries.entry.ui.bookVoucher": "Secure your training voucher now for",
    "entries.entry.ui.voucherTitle": "Your personal training voucher for",
    "entries.entry.ui.voucherIntro": "You want to get to know the groundbreaking Bodystreet method without obligation? Then get your personal training voucher for a complete Bodystreet intensive training with personal trainer and everything that goes with it.",
    "entries.entry.ui.voucherInfo": "This is how it works: Simply enter your name, email address and phone number above and send it off. You will then receive your voucher by e-mail and the studio will call you to arrange an appointment. Please do not forget to bring the voucher to the training. Only 1 voucher can be redeemed per person.",
    "entries.entry.ui.noTrial": "does not offer a voucher at the moment. Do you want to contact us directly instead?",
    "entries.entry.ui.magiclineNoID": "No ID is stored for your Magicline. You cannot connect. Please contact your contact person.",
    "entries.entry.ui.globalCampaign": "Show general training voucher form",
    "entries.entry.ui.provenExpertsID": "ProvenExperts ID",
    "entries.entry.ui.noRights": "This field cannot be changed.",
    "entries.entry.ui.jobAvailableAt": "Is available at these locations",
    "entries.entry.ui.tabRelationsMobile": "Connections",
    "entries.entry.ui.tabImagesMobile": "Image/Video",
    "entries.entry.ui.miscellaneousSettings": "Other settings",
    "entries.entry.ui.provenExpertsSettings": "ProvenExperts",
    "entries.entry.ui.globalPromotionSettings": "Standard Promotion Voucher",
    "entries.entry.ui.globalPromotionPrice": "Text and prize info for voucher",
    "entries.entry.ui.facebookSettings": "Facebook",
    "entries.entry.ui.facebookTracking": "Facebook tracking",
    "entries.entry.ui.calendly": "Calendly URL Component",
    "entries.entry.ui.studioSoftware": "Studio Software",
    "entries.entry.ui.socialPostOpen": "Open this post",
    'entries.entry.ui.openingWeek': 'Opening hours this week',
    "entries.fields.zenplanner": "Book your free consultation now",
    "job.application.documents": "Application",
    "job.application.message": "Note",
    "job.application.reason": "Why are you applying to Bodystreet?",
    "job.application.trainer": "Why are you the perfect coach?",
    'job.fields.yourDuties': 'Your duties',
    "job.fields.yourOpportunities": "What you learn with us",
    "job.fields.yourProfile": "Your profile",
    "job.fields.jobPartner": "Our training and study partners",
    "job.fields.jobContent": "Your chances + advantages",
    "job.fields.aboutUs": "What we offer you",
    "user.ui.fullName": "First and last name",
    "user.ui.dateWish": "Desired date",
    "user.ui.messageWish": "Special wishes",
    "campaign.title": "Current actions",
    "campaign.form.privacyInfo": "I consent to my details and data being electronically processed and stored for the purpose of processing and responding to my inquiry. The consent can be revoked at any time for the future by e-mail to the respective studio.",
    "campaign.search.location": "Your postcode",
    "campaign.submit": "Submit",
    "campaign.selectLocation": "Simply select your location, ",
    'campaign.selectedEntry': 'Selected trial studio:',
    'campaign.search.locationFull': 'Your postcode or city',
    "campaign.selectStudio": "select your studio, ",
    "campaign.start": "sign up and get training!",
    "campaign.form.intro": "I would like to work {{product}} in the studio <span class='form-studio-title'>{{title}}</span> for the price of {{productPrice}}.",
    "campaign.form.introNoPrice": "I would like to work {{product}} in the studio <span class='form-studio-title'>{{title}}</span>.",
    "user.ui.uploadDrop": "To upload, drag a file here or",
    "user.ui.uploadSelectFile": "select a file",
    "entries.entry.ui.jobReplyMail": "Send applications to the following e-mail address (if empty, the mail will go to the studio e-mail address)",
    "entries.entry.ui.jobReplyMailInvalid": "Please enter a valid Bodystreet email address",
    "user.ui.newsletterEmail": "Email",
    "user.ui.newsletterEmailnotValid": "Please enter your email",
    "user.ui.newsletterEmailValid": "Subscribe now!",
    'country.DE': 'Germany',
    'country.EU': 'EU',
    'country.NOEU': 'World',
    'checkout.checkout.status': 'Payment and delivery',
    'checkout.checkout.delivery.addressNotComplete': 'Your email address is missing or your billing and shipping address is not yet complete. Please complete your entry.',
    // shop
    'user.ui.accountMenuCompanySettings': 'Company information',
    'user.ui.accountMenuWishlist': 'on my wishlist',
    'user.ui.removeFromWishlist': 'remove from wishlist',
    'user.ui.accountOrdersEmpty': 'You have not ordered anything yet.',
    'user.ui.accountMenuWishlistEmpty': 'You have not yet added any products to your wishlist.',
    'widget.gdpr.personalisation': 'Personalised content, performance measurement, development and improvement of personalised offers',
    'widget.gdpr.advertising': 'Audience measurements with storage and access to information on an end device',
    'widget.gdpr.social': 'Social media cookies'
  });
});