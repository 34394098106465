define("webapp/locales/de/translations-base", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    "account.ui.entryBannerImageNew": "Lege ein Hauptbild für Deinen Eintrag fest.",
    "account.ui.profileBannerImageNew": "Lege ein Hauptbild für Dein Profil fest.",
    "account.ui.register": "Registrieren",
    "account.ui.registeredAs": "Sie sind momentan registriert als",
    "account.ui.personate": "Benutzeridentität wechseln",
    "account.ui.userNameNotEditableTooltip": "Ihr Benutzername. Sie können den angegebenen Benutzernamen nicht ändern. Für eine Änderung Ihres Benutzernamens setzen Sie sich bitte mit Ihrem Ansprechpartner in Verbindung. Wichtig: Nach einer Änderung müssen Sie sich erneut mit dem neuen Benutzernamen anmelden.",
    "account.ui.additionalDeliveryAddresses": "Sie haben abweichende Lieferadressen angegeben.",
    "account.ui.editDeliveryAddresses": "Lieferadresse bearbeiten",
    "account.ui.editInvoiceAddress": "Unternehmensadresse bearbeiten",
    "account.ui.noDeliveryAddresses": "Sie haben noch keine abweichende Lieferadresse angegeben.",
    "account.ui.addDeliveryAddresses": "Eine {{further}} abweichende Lieferadresse hinzufügen",
    "account.ui.addDeliveryAddressTitle": "Lieferadresse hinzufügen",
    "account.ui.editDeliveryAddressTitle": "Lieferadresse bearbeiten",
    "account.ui.isDefaultAddress": "Das ist die Standard-Lieferadresse",
    "account.ui.saveToApplyChanges": "Sie müssen die Änderung speichern, dann können Sie z.B. abweichende Lieferadressen angeben.",
    "accountTypes.aussteller": "Aussteller",
    "accountTypes.gastgeber": "Gastgeber",
    "accountTypes.veranstalter": "Veranstalter",
    "application.beta.infotext": "Es sind keine Abonnements angelegt.",
    "application.breadcrumbsHome": "Home",
    "application.month": "pro Monat",
    "application.newsletter.alreadySubscribed": "Sie sind bereits Abonnement des {{list}} Newsletters. Sie können die Newsletter-Abonnements in Ihrem Account aufrufen.",
    "application.newsletter.alreadySubscribedNoList": "Sie haben sich bereits für den Newsletter angemeldet",
    "application.newsletter.goToList": "Mailingliste aufrufen",
    "application.newsletter.noAccess": "Der angebotene Newsletter kann mit deinen Berechtigungen nicht abonniert werden.",
    "application.newsletter.subscribeButton": "E-Mail-Adresse eingeben, um den Newsletter zu abonnieren",
    "application.newsletter.subscribeButtonMobile": "E-Mail",
    "application.newsletter.subscribe": "Abonnieren",
    "application.newsletter.subscribeList": "Du abonnierst diesen Newsletter aus: ",
    "application.newsletter.subscribeThanks": "Danke für dein Newsletter-Abonnement. Du erhältst in Kürze den aktuellen Newsletter.",
    "application.newsletter.subscribeThanksOptIn": "Danke für dein Ihr Newsletter-Abonnement. Du bekommst zur Aktivierung noch eine Bestätigungsmail für das Opt-In.",
    "application.newsletter.subscribeWithAccount": "Mit deinem derzeitigen angemeldeten Benutzer abonnieren.",
    "application.noCosts": "Kostenlos",
    "application.placeholderImageText": "Ohne Abbildung",
    "application.searchAllGroups": "in allen ",
    "application.searchAllRegions": "in allen Orten",
    "application.ui.access": "aufrufen",
    "application.ui.action": "Aktion",
    "application.ui.addDocument": "Dokument hinzufügen",
    "application.ui.addRelation": "Verbindung hinzufügen",
    "application.ui.addImage": "Bild hinzufügen",
    "application.ui.addImages": "Bilder hinzufügen",
    "application.ui.addMedia": "Bilder/Videos hinzufügen",
    "application.ui.addVideo": "Video hinzufügen",
    "application.ui.advancedEdit": "Erweitert",
    "application.ui.all": "Alle",
    'application.ui.alreadyFavorite': "Dieser Artikel ist auf Ihrer Merkliste",
    'application.ui.makeFavorite': "Diesen Artikel zu Ihrer Merkliste hinzufügen",
    "application.ui.also": "Auch",
    "application.ui.alphabetical": "Alphabetisch",
    "application.ui.and": " und ",
    "application.ui.at": "am",
    "application.ui.in": "in",
    "application.ui.atHour": "um",
    "application.ui.hour": "Uhr",
    'application.ui.perHour': 'Stunde',
    'application.ui.day': 'Tag',
    'application.ui.days': 'Tage',
    'application.ui.daily': 'Täglich',
    'application.ui.week': 'Woche',
    'application.ui.weekly': 'Wöchentlich',
    'application.ui.biWeekly': 'Alle zwei Wochen',
    'application.ui.month': 'Monat',
    'application.ui.monthly': 'Monatlich',
    'application.ui.biMonthly': 'Alle zwei Monate',
    'application.ui.year': 'Jahr',
    "application.ui.back": "zurück",
    "application.ui.buy": "kaufen",
    "application.ui.certificate": "Zertifikate",
    "application.ui.confirmDeleteEntry": "Den Eintrag {{title}} unwiderruflich löschen?",
    "application.ui.contact": "Kontakt",
    "application.ui.count": "Anzahl",
    "application.ui.currentlyActive": "Läuft gerade",
    "application.ui.deleteEntry": "löschen",
    "application.ui.discardUnsavedChanges": "Änderungen verwerfen und fortfahren",
    "application.ui.discardUnsavedChangesMobile": "Verwerfen",
    "application.ui.distance": "Distanz",
    "application.ui.distant": "entfernt",
    "application.ui.dreampoints": "Dreampoints",
    "application.ui.dreampointsAllDreams": "Dreampoints deiner Einträge",
    "application.ui.dreampointsDonate": "zu spendende Dreampoints",
    "application.ui.dreampointsTooltip": "Du kannst mit Deinem Eintrag Dreampoints sammeln",
    "application.ui.edit": "ändern",
    "application.ui.editDescription": "Beschreibung bearbeiten",
    "application.ui.editEntry": "bearbeiten",
    "application.ui.editEvent": "Veranstaltung bearbeiten",
    "application.ui.editImage": "Bild bearbeiten",
    "application.ui.editPoi": "Standort bearbeiten",
    "application.ui.editProduct": "Produkt bearbeiten",
    "application.ui.editProfile": "Mein Profil bearbeiten",
    "application.ui.editShownProfile": "Meine Profilanzeige ändern",
    "application.ui.entriesFocus": "Ausgewählte Einträge im Focus:",
    "application.ui.entriesLatest": "Neueste Einträge im Focus:",
    "application.ui.email": "E-Mail",
    "application.ui.for": "für",
    "application.ui.from": "von",
    "application.ui.fullView": "Gesamtansicht",
    "application.ui.gallery": "Bilder",
    "application.ui.galleryImages": "zur Galerie...",
    "application.ui.gavePoints": "hat dir {{points}} Punkte gegeben.",
    "application.ui.goToEntry": "Eintrag aufrufen",
    "application.ui.goToEvent": "Veranstaltung aufrufen",
    "application.ui.goToPoi": "Ort aufrufen",
    "application.ui.goToMeasuring": "Messstellen",
    "application.ui.goToProduct": "Produkt aufrufen",
    "application.ui.hasBeen": "wurde",
    "application.ui.here": "In der Nähe",
    "application.ui.invalidEmbed": "Diese URL kann nicht eingebetted werden.",
    "application.ui.imprintLink": "Inhaltlich und rechtlich verantwortlich für den Inhalt dieser Unterseite {{entry}} – Impressum",
    "application.ui.isActive": "Aktiv",
    "application.ui.isNotActive": "Deaktiviert",
    "application.ui.knowMore": "Alles {{aboutPre}} {{about}}",
    "application.ui.language.de": "Deutsch",
    "application.ui.language.en": "English",
    "application.ui.language.fr": "Français",
    "application.ui.language.it": "Italiano",
    "application.ui.language.nl": "Nederlands",
    "application.ui.language.pl": "Polski",
    "application.ui.language.es": "Español",
    "application.ui.language.sv": "Svensk",
    "application.ui.language.tr": "Türkçe",
    "application.ui.language.cs": "Česky",
    "application.ui.language.ru": "Русский",
    "application.ui.language.zh": "中国",
    "application.ui.language.pt-br": "Português do Brasil",
    "application.ui.language.ua": "українська",
    "application.ui.language.fi": "Suomalainen",
    "application.ui.language.current": "Aktuell ausgewählte Sprache",
    "application.ui.language.switchTo": "Sprache wechseln zu ",
    "application.ui.mailBodyPost": "",
    "application.ui.mailBodyPre": "Hallo, ich habe etwas interessantes gefunden: ",
    "application.ui.mailSubject": "Ich habe einen Interessanten Link gefunden: ",
    "application.ui.mandatory": "Pflichtfeld",
    "application.ui.memberSince": "Mitglied seit ",
    "application.ui.mobileRotate": "Drehen für Vollbildansicht",
    "application.ui.more": "mehr",
    "application.ui.name": "Name",
    "application.ui.navigateOneLevelUp": "Zurück zum übergeordneten Ordner",
    "application.ui.new": "Neu",
    "application.ui.newEntry": "Neuen Eintrag anlegen",
    "application.ui.newEvent": "Neue Veranstaltung anlegen",
    "application.ui.newPoi": "Neuen Standort anlegen",
    "application.ui.newProduct": "Neues Produkt anlegen",
    "application.ui.noEntry": "Sie haben noch keinen Eintrag angelegt. Jetzt gleich den ersten Eintrag anlegen...",
    "application.ui.noEntryProfile": "hat noch keinen Eintrag angelegt.",
    "application.ui.noEvent": "Sie haben noch keine Veranstaltung angelegt. Jetzt gleich die erste Veranstaltung anlegen...",
    "application.ui.noPoi": "Sie haben noch keinen Ort angelegt. Jetzt gleich den ersten Ort anlegen...",
    "application.ui.noJob": "Sie haben noch keinen Job angelegt. Jetzt den ersten Job anlegen...",
    "application.ui.noProduct": "Sie haben noch kein Produkt angelegt. Jetzt gleich das erste Produkt anlegen...",
    "application.ui.notEditable": "kann nicht verändert werden",
    "application.ui.nothingFound": "Für diese Suche konnten wir keine Ergebnisse finden.",
    "application.ui.offer": "Angebote",
    "application.ui.offer.offers": "Angebote",
    "application.ui.offer.acceptOffer": "Angebot annehmen",
    "application.ui.offer.rejectOffer": "Angebot ablehnen",
    "application.ui.offer.noOffers": "Keine Angebote",
    "application.ui.offer.expiresAt": "Angebot läuft bis",
    "application.ui.offer.offerExpires": "Angebot läuft aus",
    "application.ui.offer.setOtherExpirationDate": "Anderes datum setzen",
    "application.ui.offer.inXDays": "in 30 Tagen",
    "application.ui.only": "Nur",
    "application.ui.openDocument": "Datei öffnen",
    "application.ui.or": "oder",
    "application.ui.per": "pro",
    "application.ui.playVideo": "Video abspielen",
    "application.ui.readMore": "Weiterlesen",
    "application.ui.region": "Region",
    "application.ui.relevance": "Relevanz",
    "application.ui.reset": "Alle Filter zurücksetzen",
    "application.ui.routingDescription": "Anfahrtsbeschreibung",
    "application.ui.saveShownProfile": "Profiländerungen speichern",
    "application.ui.saveUnsavedChanges": "Speichern",
    "application.ui.searchEnter": "Suchbegriff eingeben und ⏎ drücken",
    "application.ui.select": "Bitte auswählen",
    "application.ui.shop": "Shop",
    "application.ui.showAllEntries": "Alle Einträge anzeigen",
    "application.ui.showAllSearch": "Alle Suchergebnisse anzeigen",
    "application.ui.showMoreSearch": "Mehr Suchergebnisse anzeigen",
    "application.ui.resetSearch": "Auswahl zurücksetzen und erneut suchen",
    "application.ui.status": "Status",
    "application.ui.sort": "Sortierung",
    "application.ui.stockPhotoLinks": "Links zu Bilddatenbanken",
    "application.ui.suitableFor": "Empfohlen für ",
    "application.ui.title": "Titel",
    "application.ui.createdAt": "Erstellt am",
    "application.ui.total": "Gesamtsumme",
    "application.ui.totalDreampoints": "Dreampoints aller deiner Einträge",
    "application.ui.trySearch": "Willst du es mit anderen Suchbegriffen probieren, wie zum Beispiel",
    "application.ui.type": "Typ",
    "application.ui.unsavedChanges": "Ungespeicherte Änderungen",
    "application.ui.updated": "aktualisiert",
    "application.ui.updatedAt": "geändert",
    "application.ui.uploadedFileFor": "Hochgeladene Datei für: ",
    "application.ui.userName": "Benutzername ",
    "application.ui.viewAsList": "Listenansicht",
    "application.ui.viewAsTable": "Tabellenansicht",
    "application.ui.visibleForAll": "Für alle Benutzer sichtbar",
    "application.ui.visibleForFollower": "Für Follower sichtbar",
    "application.ui.visibleForJoiner": "Für Joiner sichtbar",
    "application.ui.visibleForNone": "Für Niemanden sichtbar",
    "application.ui.visibleForSupporter": "Für Supporter sichtbar",
    "application.ui.years": "Jahre",
    "application.ui.yearsFew": "Babyalter",
    "application.widgets.highestRatedEntries": "Die meisten Dreampoints",
    "application.widgets.inFocus": "Im Focus",
    "application.widgets.newestEntries": "Die neuesten {{size}} Einträge",
    "application.widgets.noNewestEntries": "Keine neuen Einträge gefunden...",
    "application.widgets.showCategories": "Kategoriefilter anzeigen",
    "application.widgets.showFilter": "Weitere Filter anzeigen",
    "application.ui.writeUs": "Schreiben Sie uns…",
    "blog.ui.category": "Kategorie",
    "blog.ui.categoryHeading": "Aus der Kategorie",
    "blog.ui.lastUpdated": "Zuletzt aktualisiert am",
    "blog.ui.openSinglePost": "Weiterlesen",
    "blog.ui.publishedIn": "Veröffentlicht in",
    "blog.ui.subCategories": "Unterkategorien",
    "blog.ui.written": "Geschrieben",
    "blog.ui.writtenBy": "Geschrieben von",
    "buttons.alreadyMeToo": "Schön, dass du diesen Eintrag auch träumst.",
    "buttons.alreadyMeTooMobile": "Schön, dass du diesen Eintrag auch träumst.",
    "buttons.cancel": "Abbrechen",
    "buttons.confirm": "Bestätigen",
    "buttons.createAccount": "Jetzt EntryerAccount einrichten",
    "buttons.delete": "löschen",
    "buttons.deleteFavorite": "Nicht mehr folgen",
    "buttons.deleteLike": "Nicht mehr liken",
    "buttons.meToo": "Ich möchte das auch machen",
    "buttons.newPassword": "Neues Passwort anfordern",
    "buttons.ok": "Ok",
    "buttons.ready": "Fertig",
    "buttons.save": "Änderungen speichern",
    "buttons.saveMobile": "Speichern",
    "buttons.selectThis": "Dieses auswählen",
    "buttons.tryAgain": "Bitte probiere es noch einmal",
    "category.ui.hideCategories": "Unterkategorien ausblenden",
    "category.ui.oneLevelUp": "Zurück zur übergeordeneten Kategorie",
    "category.ui.showCategories": "Unterkategorien anzeigen",
    "categoryFields.additionalGauge": "",
    "categoryFields.additionalWeight": "",
    "categoryFields.attachmentWidth": "Anbaugerätebreite",
    "categoryFields.booth": "",
    "categoryFields.clutch": "Kupplung",
    "categoryFields.companyName": "Firmenname",
    "categoryFields.customAward": "",
    "categoryFields.dealerInfo": "Händlerinformation",
    "categoryFields.diameter": "Durchmesser",
    "categoryFields.displayNetPrice": "Nettopreis",
    "categoryFields.electricalPower": "Elektrische Leistung",
    "categoryFields.elektricAttachment": "",
    "categoryFields.engine": "Motor",
    "categoryFields.engineDescription": "Motorausstattung",
    "categoryFields.ERPname": "Name in der Warenwirtschaft",
    "categoryFields.horsePower": "Leistung",
    "categoryFields.netWidth": "Spurbreite ohne Reifen",
    "categoryFields.optionalDrive": "",
    "categoryFields.optionalIntake": "",
    "categoryFields.optionalPanning": "",
    "categoryFields.packingUnit": "Verpackungseinheit",
    "categoryFields.PTO": "Zapfwelle",
    "categoryFields.relativeGauge": "Verbreitert die Spur um",
    "categoryFields.sectionWidth": "",
    "categoryFields.vendorAcronym": "Kurzbezeichnung",
    "categoryFields.volume": "Volumen",
    "categoryFields.weight": "Gewicht",
    "categoryFields.wheelLock": "Stand-Radblockierung",
    "checkout.cart.title": "Mein Warenkorb",
    "checkout.cart.item": "Artikel",
    "checkout.cart.amount": "Anzahl",
    "checkout.cart.edit": "Ändern",
    "checkout.cart.price": "Preis",
    "checkout.cart.singlePrice": "Einzelpreis",
    "checkout.cart.product.isNonPhysical": "Produkt wird elektronisch/per Download versendet.",
    "checkout.cart.product.requiresExpressDelivery": "Frischeprodukt mit EXPRESS Versand. Nur innerhalb D.",
    "checkout.cart.product.deliveryProblem": "Beim ausgewähltem Produkt kann es zu Lieferverzögerungen kommen.",
    "checkout.cart.subtotal": "Zwischensumme",
    "checkout.cart.deliveryCosts": "Versandkosten",
    "checkout.cart.total": "Gesamtsumme",
    "checkout.cart.help": "Haben Sie Fragen zum Bestellvorgang? Wir haben wichtige Informationen für Sie zusammengestellt.",
    "checkout.cart.subtotalInfo": "inkl. MwSt.",
    "checkout.cart.subtotalShipment": "zzgl. voraussichtlicher Versandkosten",
    "checkout.cart.checkout": "Zur Kasse",
    "checkout.cart.checkoutWithoutUser": "Zur Kasse ohne Kundenkonto",
    "checkout.cart.checkoutFast": "Schnell-Checkout",
    "checkout.cart.deliveryInfo": "Versandtermin für die Artikel im Warenkorb",
    "checkout.cart.orderSuccess": "Ihre Bestellung war erfolgreich.",
    "checkout.cart.orderCommitting": "Ihre Bestellung wird verarbeitet - bitte warten",
    "checkout.cart.myOrder": "Meine Bestellung",
    "checkout.cart.leftover": "übrig",
    "checkout.checkout.orderNumber": "Bestellnummer",
    "checkout.checkout.account": "Kundenkonto",
    "checkout.checkout.alreadyAccount": "Sie sind bereits Kunde? Klicken Sie hier, um sich anzumelden.",
    "checkout.checkout.andOrder": "und Bestellung",
    "checkout.checkout.delivery.title": "Adressen",
    "checkout.checkout.delivery": "Lieferung und Lieferadresse",
    "checkout.checkout.delivery.type": "Der Versand erfolgt mit",
    "checkout.checkout.delivery.street": "Adresszeile 1",
    "checkout.checkout.delivery.houseNumber": "Nr.",
    "checkout.checkout.delivery.co": "Adresszusatz (c/o)",
    "checkout.checkout.delivery.city": "Stadt",
    "checkout.checkout.delivery.selectCountry": "Bitte Land auswählen",
    "checkout.checkout.delivery.addressBoth": "Rechnungs- und Lieferadresse",
    "checkout.checkout.delivery.addressInvoice": "Rechnungsanschrift",
    "checkout.checkout.delivery.addressDelivery": "Lieferanschrift",
    "checkout.checkout.delivery.default": "Diese Adresse als meine Standard-Lieferadresse festlegen",
    "checkout.checkout.delivery.otherAddress": "Die Lieferadresse weicht von der Rechnungsadresse ab",
    "checkout.checkout.delivery.dhlNr": "Ihre DHL Postnummer",
    "checkout.checkout.delivery.packingStation": "Nummer der Packstation",
    "checkout.checkout.delivery.showPackingStations": "Packstationen in der Umgebung meiner Adresse anzeigen",
    "checkout.checkout.delivery.wantExpressShipping": "Express-Lieferung",
    "checkout.checkout.delivery.isPickUp": "Abholung vor Ort",
    "checkout.checkout.delivery.addressNotVerified": "Ihre gewählte Adresse ist nicht vollständig oder wurde noch nicht überprüft. Wir können nicht zu Ihnen liefern. Um Ihre Adresse zu vervollständigen klicken Sie bitte auf das Stiftsymbol Ihrer Adresse. Bei Fragen zum Bestellvorgang steht Ihnen unser Kundensupport mit Rat und Tat zur Seite.",
    "checkout.checkout.delivery.specialCountryInfo": "Ihr Land kann nicht ausgewählt werden?",
    "checkout.checkout.delivery.specialCountry": "Ihr Land ist außerhalb unseres Standardversands",
    "checkout.checkout.delivery.specialCountryMessage": "Einzelne Produkte in Ihrem Warenkorb können nicht in alle Länder versendet werden. Bitte entfernen Sie einige Produkte aus Ihrer Bestellung, bis Ihr Land ausgewählt werden kann oder nehmen Sie Kontakt zu uns auf.",
    "checkout.checkout.payment": "Zahlungsart",
    "checkout.checkout.paymentProblem": "Bei der Ausführung der Zahlung ist ein Fehler aufgetreten",
    "checkout.checkout.backToPayment": "Zurück zu den Zahlungseinstellungen.",
    "checkout.checkout.paymentTitle": "Du bezahlst bei uns mit …",
    "checkout.checkout.paymentSelected": "Ausgewählte Zahlungsart",
    "checkout.checkout.payment.cc": "Kreditkarte",
    "checkout.checkout.payment.ccInfo": "Bei Kartenzahlungen innerhalb der EU muss Ihre identität bei jeder Onlinezahlung bestätigt werden. Nach der Bestellung werden Sie durch die – von Ihrer Bank vorgesehene – Bestätigung geführt.",
    "checkout.checkout.payment.complimentary": "kostenlos",
    "checkout.checkout.payment.paypal": "Paypal, Überweisung oder Kreditkarte",
    "checkout.checkout.payment.paypalButton": "mit weiteren Zahlungsarten, auch ohne Paypal-Konto",
    "checkout.checkout.payment.paypalInfo": "Du wirst an PayPal weitergeleitet, um den Bezahlvorgang abzuschließen. Dein Paypal-Konto wird erst belastet, wenn du im nächsten Schritt die Bestellung bestätigst.",
    "checkout.checkout.payment.paypalInfoExtended": "Du wirst an PayPal weitergeleitet, um den Bezahlvorgang abzuschließen. Dein Konto oder Paypal-Konto wird erst belastet, wenn du im nächsten Schritt die Bestellung bestätigen.",
    "checkout.checkout.payment.sepa": "Lastschrift (SEPA)",
    "checkout.checkout.payment.sepaLegal": "Ich ermächtige {{siteOwner}}, {{siteOwnerAddress}} (Gläubiger-ID: {{siteOwnerCreditorID}}) Zahlungen von meinem Konto mittels Lastschrift einzuziehen. Zugleich weise ich mein Kreditinstitut an, die von {{siteOwner}} auf mein Konto gezogenen Lastschriften einzulösen.",
    "checkout.checkout.payment.noSepa": "Ihre Rechnungs- oder Lieferadresse liegt außerhalb der EU. Deshalb können wir Ihnen keine Bezahlung per Lastschrift anbieten.",
    "checkout.checkout.payment.sepaInfo": "Schnell und unkompliziert. Bei Abschluss Ihrer Bestellung (nächster Schritt) werden Sie aufgefordert Ihre Bankverbindung (IBAN) anzugeben. Halten Sie diese bitte bereit. Wenn die Überweisung abgeschlossen ist, erhalten Sie eine Bestätigung.",
    "checkout.checkout.payment.prepayment": "Vorkasse (Überweisung)",
    "checkout.checkout.payment.prepaymentInfo": "Nachdem Sie die Bestellung aufgegeben haben, senden wir Ihnen die Informationen zur Banküberweisung per E-Mail. Wir können die Artikel Ihrer Bestellung nur für maximal 7 Tage reservieren. Bitte halten Sie dieses Zeitfenster für die Überweisung ein. Sobald wir die Zahlung erhalten, wird die Bestellung verschickt.",
    "checkout.checkout.payment.invoice": "Rechnung",
    "checkout.checkout.payment.invoiceInfo": "Der Rechnungsbetrag wird zu dem in der Versandbestätigung per E-Mail angegebenen Zahlungsziel fällig.",
    "checkout.checkout.payment.noInvoice": "Für diese Bestellung können wir Ihnen leider keine Zahlung per Rechnung anbieten. Wählen Sie bitte eine der anderen Zahlungsarten aus.",
    "checkout.checkout.payment.sofort": "Sofortüberweisung",
    "checkout.checkout.payment.sofortInfo": "Bei Abschluss Ihrer Bestellung (nächster Schritt) werden Sie aufgefordert Ihre Bankverbindung (IBAN) anzugeben. Halten Sie diese bitte bereit. Wenn die Überweisung abgeschlossen ist, erhalten Sie eine Bestätigung.",
    "checkout.checkout.payment.accountName": "Kontoinhaber",
    "checkout.checkout.payment.accountEmail": "E-Mail-Adresse zur Bestätigung",
    "checkout.checkout.sepaAndOrder": "Lastschriftmandat für {{totalAmount}} € bestätigen und kostenpflichtig bestellen",
    "checkout.checkout.sofortAndOrder": "Überweisungsmandat für {{totalAmount}} € bestätigen und kostenpflichtig bestellen",
    "checkout.checkout.title": "Kasse",
    "checkout.checkout.next": "weiter",
    "checkout.checkout.orderPre": "Klicke auf „Jetzt kaufen“, um deine Bestellung abzuschließen",
    "checkout.checkout.order": "Jetzt kaufen",
    "checkout.checkout.orderConsentPre": "Mit der Bestellung erklärst du dich mit unseren ",
    "checkout.checkout.orderConsentPost": " einverstanden.",
    "checkout.checkout.noAccount": "Kein Kundenkonto anlegen",
    "checkout.redirect.countryPriceChange": "Die Preise für Ihr Land weichen vom Standardpreis ab. Bitte bestätigen Sie die Preisänderung.",
    "comments.blockComments": "Die Kommentierung für diesen Post wurde beendet.",
    "comments.comment.anonymous": "Anonym",
    "comments.comment.button": "Einen neuen Kommentar verfassen",
    "comments.comment.buttonReply": "Antworten",
    "comments.comment.cancel": "Abbrechen",
    "comments.comment.content": "Ihr Kommentar",
    "comments.comment.hasCommented": "kommentiert",
    "comments.comment.login": "Melden Sie sich an, um einen Kommentar abzugeben",
    "comments.comment.past": "hat",
    "comments.comment.replyFrom": "Antwort auf",
    "comments.comment.showReplies": "Antworten anzeigen",
    "comments.comment.showReply": "Antwort anzeigen",
    "comments.comment.submit": "Absenden",
    "comments.comment.title": "Kommentartitel",
    "comments.comment.userName": "Ihr Benutzername",
    "comments.heading": "Anregungen, Anmerkungen, Kommentare",
    "comments.hierarchy.flat": "Flach",
    "comments.hierarchy.nested": "Genested",
    "comments.hierarchy.title": "Kommentaransicht:",
    "comments.message.afterSaveError": "Ihr Kommentar kann zur Zeit nicht gespeichert werden!",
    "comments.message.afterSaveSuccess": "Ihr Kommentar wurde gespeichert.",
    "comments.message.afterSaveUnlock": "Ihr Kommentar wurde gespeichert, muss allerdings noch von einem Administrator überprüft werden.",
    "comments.noComments": "Es wurden noch keine Kommentare abgegeben",
    "comments.reply": "AN: ",
    "comments.show": "Kommentare einblenden",
    "comments.title": "Kommentare",
    "entries.edit": "Eintrag <strong>{{title}}</strong> bearbeiten",
    "entries.entries": "Einträge",
    "entries.product.ui.chooseVariant": "Wählen Sie eine Variante aus",
    "entries.product.ui.chooseVariantMobile": "Variante auswählen",
    "entries.product.ui.combinationNotAvailable": "Kombination nicht möglich",
    "entries.entry.fields.address.city": "Stadt \n",
    "entries.entry.fields.address.country": "Land \n",
    "entries.entry.fields.address.houseNumber": "Hausnummer \n",
    "entries.entry.fields.address.street": "Straße \n",
    "entries.entry.fields.address.zip": "PLZ \n",
    "entries.entry.fields.language": "Sprache",
    "entries.entry.fields.prefixMissing": "Folgende Felder sind nicht ausgefüllt: \n",
    "entries.entry.profile.edit": "Profil bearbeiten",
    "entries.entry.profile.publicProfile": "zu meiner öffentlichen Profilseite",
    "entries.entry.tooltip.alreadyCopied": "Du träumst diesen Eintrag bereits.",
    "entries.entry.tooltip.alreadyFollow": "Du folgst diesem Eintrag bereits",
    "entries.entry.tooltip.alreadyLiked": "Du magst diesen Eintrag bereits",
    "entries.entry.tooltip.copied": "Schön, dass du diesen Eintrag auch träumen willst. Dein Profilbild wurde diesem Eintrag hinzugefügt.",
    "entries.entry.tooltip.filterComments": "Hier Klicken, um in der Entryline nur Comments anzuzeigen",
    "entries.entry.tooltip.filterFollows": "Hier Klicken, um in der Entryline nur Follows anzuzeigen",
    "entries.entry.tooltip.filterLikes": "Hier Klicken, um in der Entryline nur Likes anzuzeigen",
    "entries.entry.tooltip.filterShares": "Hier Klicken, um in der Entryline nur Shares anzuzeigen",
    "entries.entry.tooltip.follow": "Hier klicken, um diesen Eintrag zu folgen",
    "entries.entry.tooltip.like": "Hier klicken, um diesen Eintrag ein Like zu geben",
    "entries.entry.tooltip.noFollow": "Follow geht nur bei fremden Einträgen",
    "entries.entry.tooltip.noFollowNotLoggedIn": "Du musst angemeldet sein, um diesen Eintrag zu folgen",
    "entries.entry.tooltip.noLike": "Like geht nur bei fremden Einträgen",
    "entries.entry.ui.access": "Sehen können diesen Eintrag nur",
    "entries.entry.ui.added": "…wurde hinzugefügt.",
    "entries.entry.ui.addEntry": "Einträge hinzufügen",
    "entries.entry.ui.addOpening": "Öffnungszeit hinzufügen",
    "entries.entry.ui.address": "Adressdaten",
    "entries.entry.ui.addStep": "Einen Schritt hinzufügen",
    "entries.entry.ui.agreementHours": "Geöffnet nach Absprache",
    "entries.entry.ui.allAccess": "Alle können diesen Eintrag sehen",
    "entries.entry.ui.assignedCategories": "zugewiesene Kategorien",
    "entries.entry.ui.assignedCertificates": "Meine Zertifizierungen",
    "entries.entry.ui.assignedProducts": "zugewiesene Produkte",
    "entries.entry.ui.businessContact": "Unternehmens-Kontaktdaten",
    "entries.entry.ui.categories": "Kategorien für diesen Eintrag",
    "entries.entry.ui.categoryFields": "Weitere Angaben",
    "entries.entry.ui.commercialTitle": "Unternehmensinformationen zum Eintrag",
    "entries.entry.ui.companyTitle": "Meine Unternehmensangaben",
    "entries.entry.ui.contactPerson": "Meine Kontaktdaten",
    "entries.entry.ui.cssClass": "CSS-Klasse für den Inhalt",
    "entries.entry.ui.deliveryInfo": "Informationen zum Lieferdienst",
    "entries.entry.ui.descriptionInfo": "Tipp: Wenn Wörter markiert sind, können Stile zugewiesen werden.",
    "entries.entry.ui.descriptionPlaceholder": "Beschreibung mit HTML. Doppelklick auf Text für HTML-Funktionsleiste (maximal 600 Zeichen).",
    "entries.entry.ui.editEntry": "Eintrag bearbeiten",
    "entries.entry.ui.editEntryNew": "Eintrag anlegen",
    "entries.entry.ui.exclusionHours": "Ausschlusszeiten",
    "entries.entry.ui.galleryImages": "Bildergalerie (2 oder 3 Bilder je nach Anordnung)",
    "entries.entry.ui.globalTitle": "Name",
    "entries.entry.ui.hasKiosk": "Ich habe einen Verkaufsstand",
    "entries.entry.ui.imagesHorizontal": "Horizontal",
    "entries.entry.ui.imagesInfo": "Bilder können unter \"Bilder/Videos/Dokumente\" bearbeitet werden.",
    "entries.entry.ui.imagesOrientation": "Bildausrichtung",
    "entries.entry.ui.imagesSquared": "Quadratisch",
    "entries.entry.ui.imagesVertical": "Vertikal",
    "entries.entry.ui.initiative": "Initiativbewerbung",
    "entries.entry.ui.isListImage": "Dieses Bild ist das Listenbild",
    "entries.entry.ui.isMainImage": "Dieses Bild ist das Hauptbild",
    "entries.entry.ui.isMainImageAndListImage": "Dieses Bild erscheint als Hauptbild des Eintrags",
    "entries.entry.ui.jobApply": "Jetzt bewerben",
    "entries.entry.ui.kioskDescription": "Beschreibung Ihres Verkaufsstandes",
    "entries.entry.ui.listImage": "Listenbild",
    "entries.entry.ui.logo": "Logo",
    "entries.entry.ui.longDescription": "Langer Beschreibungstext",
    "entries.entry.ui.mainImage": "Hauptbild",
    "entries.entry.ui.makeListImage": "Dieses Bild als Listenbild festlegen",
    "entries.entry.ui.makeListImageTooltip": "Suchergebnisse werden als Liste mit dem Listenbild angezeigt. Ist kein Listenbild ausgewählt, wird das Hauptbild verwendet.",
    "entries.entry.ui.makeMainImage": "Dieses Bild als Hauptbild festlegen",
    "entries.entry.ui.makeMainImageTooltip": "Das Hauptbild wird oben im Profil angezeigt. Bilder, die nicht als Haupt- oder Listenbild ausgewählt sind, werden in der Bildergalerie angezeigt.",
    "entries.entry.ui.metaDescription": "Kurzbeschreibung",
    "entries.entry.ui.metaDescriptionPlaceholder": "Kurzbeschreibung mit maximal 200 Zeichen",
    "entries.entry.ui.multiCode": "Zusätzlicher Code für diesen Schritt",
    "entries.entry.ui.multiDescription": "Beschreibung dieses Schrittes",
    "entries.entry.ui.multiImages": "Bilder für diesen Schritt",
    "entries.entry.ui.multiProgress": "Fortschritt ",
    "entries.entry.ui.multiTitle": "Titel für diesen Schritt",
    "entries.entry.ui.multiTitlePlaceholder": "Titel für diesen Schritt (maximal 50 Zeichen)",
    "entries.entry.ui.noDescriptions": "Der Eintrag hat noch keine Beschreibungstexte.",
    "entries.entry.ui.noEntries": "Keine Einträge gefunden.",
    "entries.entry.ui.noJobs": "Keine offenen Stellen.",
    "entries.entry.ui.onlyVisibleForCustomers": "Dieser Eintrag ist nur für registrierte Kunden sichtbar",
    "entries.entry.ui.openAt": "Geöffnet",
    "entries.entry.ui.openingHours": "Öffnungszeiten",
    "entries.entry.ui.openingInfo": "Anmerkungen zu den Öffnungszeiten",
    "entries.entry.ui.openingReligious": "Adresse in überwiegend katholischer Gemeinde",
    "entries.entry.ui.overallProgress": "Dieser Eintrag ist zu {{value}}% realisiert",
    "entries.entry.ui.parking": "Parkmöglichkeiten",
    "entries.entry.ui.parkingLots": "Anzahl",
    "entries.entry.ui.parkingType": "Art",
    "entries.entry.ui.personImage": "Bild des Ansprechpartners",
    "entries.entry.ui.relatedEvents": "Verbundene Veranstaltungen",
    "entries.entry.ui.relatedJobs": "Verbundene Jobs",
    "entries.entry.ui.relatedArticles": "Verbundene Artikel",
    "entries.entry.ui.relatedCampaigns": "Verbundene Kampagnen",
    "entries.entry.ui.rentKiosk": "Ich möchte einen Verkaufsstand mieten",
    "entries.entry.ui.resolveAddress": "Koordinaten ermitteln",
    "entries.entry.ui.resolveAddressInfo": "Bitte ermitteln Sie den Geopoint (Position auf der Karte) aus Ihrer Adresse. Ohne korrekten Geopoint können sie unter Umständen nicht richtig gefunden werden.",
    "entries.entry.ui.resolveGeolocation": "Adresse ermitteln",
    "entries.entry.ui.routingPlaceholder": "Geben Sie hier Ihre Anfahrtsbeschreibung mit HTML ein.",
    "entries.entry.ui.selectEvent": "Veranstaltung auswählen",
    "entries.entry.ui.selectJob": "Job auswählen",
    "entries.entry.ui.selectArticle": "Artikel auswählen",
    "entries.entry.ui.selectCampaign": "Kampagne auswählen",
    "entries.entry.ui.sharing": "Diese Seite auf {{sharing}} teilen",
    "entries.entry.ui.shareMail": "Diese Seite per Mail teilen",
    "entries.entry.ui.shortDescriptionInfo": "Ist gleichzeitig die Kurzbeschreibung",
    "entries.entry.ui.skillsNeeded": "Für meinen Eintrag könnte ich gut gebrauchen...",
    "entries.entry.ui.sortword": "Alternativtitel für die Sortierung",
    "entries.entry.ui.step": "Schritt",
    "entries.entry.ui.stepInDream": "In welcher Phase des Eintrags ist dieser Schritt?",
    "entries.entry.ui.stepInDreamAfter": "nach der Realisation",
    "entries.entry.ui.stepInDreamBefore": "vor der Realisation",
    "entries.entry.ui.stepInDreamDuring": "während der Realisation",
    "entries.entry.ui.subtitle": "Beschreibender Untertitel",
    "entries.entry.ui.subtitlePlaceholder": "Untertitel eingeben (maximal 60 Zeichen)",
    "entries.entry.ui.tabCategories": "Kategorien",
    "entries.entry.ui.tabCategoriesMobile": "Kat.",
    "entries.entry.ui.tabCompany": "Unternehmensdaten",
    "entries.entry.ui.tabDescription": "Beschreibung",
    "entries.entry.ui.tabDescriptionMobile": "Beschr.",
    "entries.entry.ui.tabExhibitor": "Ausstellerangaben",
    "entries.entry.ui.tabImages": "Bilder/Videos/Dokumente",
    "entries.entry.ui.tabImagesMobile": "Bilder/Videos/Dateien",
    "entries.entry.ui.tabRelations": "Verbindungen",
    "entries.entry.ui.tags": "Tags",
    "entries.entry.ui.title": "Der Eintrag erscheint unter folgenden Namen",
    "entries.entry.ui.titleError": "Der Eintrag muss vor dem Speichern benannt werden",
    "entries.entry.ui.titlePlaceholder": "Titel eingeben (maximal 50 Zeichen)",
    "entries.entry.ui.visible": "Dieser Eintrag ist sichtbar",
    "entries.event.edit": "Veranstaltung <strong>{{title}}</strong> bearbeiten",
    "entries.event.ui.addEvent": "Veranstaltung hinzufügen",
    "entries.event.ui.cooperation": "Kooperationspartner",
    "entries.event.ui.directions": "Anfahrtsbeschreibung",
    "entries.event.ui.editEvent": "Veranstaltung bearbeiten",
    "entries.event.ui.editEventNew": "Veranstaltung anlegen",
    "entries.event.ui.endDate": "Veranstaltungsende (Tag)",
    "entries.event.ui.eventDone": "Messenachbericht",
    "entries.event.ui.noEvents": "Keine Veranstaltungen gefunden",
    "entries.event.ui.openEvent": "Veranstaltung aufrufen",
    "entries.event.ui.opening": "Öffnungszeiten",
    "entries.event.ui.startDate": "Veranstaltungsbeginn (Tag)",
    "entries.event.ui.tabCategories": "Kategorien",
    "entries.event.ui.tabDescription": "Beschreibung",
    "entries.event.ui.tabOpening": "Datum und Zeiten",
    "entries.event.ui.tabRelations": "Verbindungen",
    "entries.event.ui.title": "Die Veranstaltung erscheint unter folgenden Namen",
    "entries.event.ui.upcoming": "In Kürze",
    "entries.event.ui.current": "Findet momentan statt",
    "entries.event.ui.history": "Hat bereits stattgefunden",
    "entries.event.ui.moreEvents": "Weitere Veranstaltungen",
    "entries.entry.ui.promotion": "Teilnahme an Aktionen für diesen Eintrag",
    "entries.entry.ui.regions": "Regionenkategorien für diesen Eintrag",
    "entries.job.ui.promotion": "Teilnahme an Aktionen für diesen Job",
    "entries.job.ui.relatedEntries": "Einträge, bei denen der Job erscheinen soll",
    "entries.job.ui.addJob": "Job anlegen",
    "entries.job.ui.editJob": "Job bearbeiten",
    "entries.job.ui.editJobNew": "Job anlegen",
    'entries.job.ui.educationLevel': 'Bildungsniveau',
    'entries.job.ui.educationLevel.master': 'Master, PhD, Doktor',
    'entries.job.ui.educationLevel.bachelor': 'Bachelor, Absolvent',
    'entries.job.ui.educationLevel.trained': 'Abgeschlossene Ausbildung',
    'entries.job.ui.educationLevel.school': 'Haupt- oder Realschulabschluss',
    "entries.job.ui.employmentType.FULL_TIME": "Vollzeit",
    "entries.job.ui.employmentType.PART_TIME": "Teilzeit",
    "entries.job.ui.employmentType.CONTRACTOR": "Freiberuflich",
    "entries.job.ui.employmentType.TEMPORARY": "Befristet",
    "entries.job.ui.employmentType.APPRENTICE": "Ausbildung",
    "entries.job.ui.employmentType.INTERN": "Trainee",
    "entries.job.ui.employmentType.TRAINEE": "Trainee",
    "entries.job.ui.employmentType.VOLUNTEER": "Ehrenamtlich",
    "entries.job.ui.employmentType.PER_DIEM": "Tagesstelle",
    "entries.job.ui.employmentType.OTHER": "",
    "entries.job.ui.tabDescription": "Beschreibung",
    "entries.job.ui.tabDescriptionMobile": "Beschr.",
    "entries.job.ui.tabImages": "Bilder/Videos/Dokumente",
    "entries.job.ui.tabImagesMobile": "Bilder/Videos/Dateien",
    "entries.job.ui.tabCategories": "Kategorien",
    "entries.job.ui.tabCategoriesMobile": "Kat.",
    "entries.job.ui.title": "Der Job erscheint unter folgenden Namen",
    "entries.job.ui.title.placeholder": "Titel eingeben",
    "entries.job.ui.subtitle": "Beschreibender Untertitel",
    "entries.job.ui.subtitle.placeholder": "z.B. Region als beschreibenden Untertitel eingeben",
    'entries.job.ui.seniority': 'Karrierestufe',
    'entries.job.ui.seniority.executive': 'Geschäftsleitung',
    'entries.job.ui.seniority.manager': 'Teamleitung',
    'entries.job.ui.seniority.experienced': 'Berufserfahren',
    'entries.job.ui.seniority.entry_level': 'Berufseinstieg',
    'entries.job.ui.seniority.no_experience': 'Student, Trainee, Hilfstätigkeit',
    "entries.job.ui.longDescription": "Langer Beschreibungstext",
    "entries.job.ui.imagesInfo": "Bilder können unter \"Bilder/Videos/Dokumente\" bearbeitet werden.",
    "entries.job.ui.categories": "Kategorien für diesen Job",
    "entries.job.ui.regions": "Regionen, in denen der Job angeboten wird",
    'entries.job.ui.baseSalary': 'Lohn, Gehalt in €',
    'entries.job.ui.minSalary': 'min. Gehalt €',
    'entries.job.ui.maxSalary': 'max. Gehalt €',
    "entries.job.ui.locationType": "Ortsabhängigkeit",
    "entries.job.ui.isTelecommute": "Home-Office möglich",
    "entries.job.ui.substatus": "Jobstatus",
    "entries.job.ui.substatus.occupied": "Belegte Stelle",
    "entries.job.ui.substatus.open": "Offene Stelle",
    "entries.job.ui.substatus.inAssessment": "In laufenden Assessment",
    "entries.job.ui.role.gender": "Angaben zum Geschlecht",
    "entries.job.ui.role.male": "M",
    "entries.job.ui.role.female": "W",
    "entries.job.ui.role.divers": "D",
    "entries.job.ui.startPublishDate": "Job veröffentlichen ab wann",
    "entries.job.ui.endDate": "Ende der Bewerbungsfrist",
    "entries.job.ui.startDate": "Job verfügbar ab wann",
    "entries.job.ui.startDateNow": "Ab sofort",
    "entries.job.ui.shortDescriptionInfo": "Ist gleichzeitig die Kurzbeschreibung",
    "entries.open": "Eintrag im Frontend aufrufen",
    "entries.person.ui.company": "Firma",
    "entries.person.ui.female": "Frau",
    "entries.person.ui.diverse": "Ohne",
    "entries.person.ui.fullName": "Vorname und Name",
    "entries.person.ui.fullSize": "Volle Größe",
    "entries.person.ui.gender": "Geschlecht",
    "entries.person.ui.greeting": "Grußwort",
    "entries.person.ui.male": "Herr",
    "entries.person.ui.open": "Direkt zum Eintrag dieser Person",
    "entries.person.ui.personAddress": "Adresse der Person",
    "entries.person.ui.personContact": "Persönliche Kontaktdaten",
    "entries.person.ui.personImage": "Personenbild",
    "entries.person.ui.position": "Position",
    "entries.person.ui.quote": "Zitat/Bildunterschrift",
    "entries.person.ui.signature": "Unterschriftenbild",
    "entries.poi.ui.poiEvents": "Veranstaltungen an diesem Ort",
    "entries.poi.ui.title": "Der Ort erscheint unter folgenden Namen",
    "entries.product.edit": "Produkt <strong>{{title}}</strong> bearbeiten",
    "entries.product.fields.engine": "Motor",
    "entries.product.fields.horsePower": "Leistung",
    "entries.product.menu.allAttachments": "Alle Anbaugeräte in der Übersicht",
    "entries.product.menu.allCarriers": "Alle Trägerfahrzeuge in der Übersicht",
    "entries.product.menu.attachments": "Anbaugeräte",
    "entries.product.menu.carriers": "Trägerfahrzeuge",
    "entries.product.tags.eco": "eco",
    "entries.product.tags.neu": "neu",
    "entries.product.ui.addProduct": "Produkt hinzufügen",
    "entries.product.ui.choose": "auswählen",
    "entries.product.ui.discount": "Rabatt",
    "entries.product.ui.discountSelect": "% Rabattvorteil nutzen",
    "entries.product.ui.equipmentVariants": "Ausstattungsvarianten",
    "entries.product.ui.variants": "Varianten",
    "entries.product.ui.gauge": "Breite in cm",
    "entries.product.ui.gaugeMin": "Mindest-Spurbreite in cm",
    "entries.product.ui.inclusive": "Inklusive",
    "entries.product.ui.live": "{{name}} live erleben",
    "entries.product.ui.netPrize": "inkl. {{tax}}% MwSt., zzgl. ",
    "entries.product.ui.netPrizeNet": "Nettopreis zzgl. {{tax}}% MwSt., zzgl. ",
    "entries.product.ui.netPrizeInfo": "zzgl. gesetzl. MwSt. (Nettopreise)",
    "entries.product.ui.netPrizeDefault": "inkl. gesetzl. Mehrwertsteuer, zuzüglich ",
    "entries.product.ui.netPrizeDefaultNonPhysical": "inkl. gesetzl. Mehrwertsteuer.",
    "entries.product.ui.deliveryTerms": "Versandkosten",
    "entries.product.ui.noProducts": "Keine Produkte gefunden",
    "entries.product.ui.noPurchaseOnThisPage": "Kann auf dieser Seite nicht bestellt werden, rufen Sie dazu das Produkt auf.",
    "entries.product.ui.onlyBasicVariant": "Nur in Basisausstattung",
    "entries.product.ui.onlyWithCarrier": "Nur erhältlich beim Kauf eines Grundgerätes ",
    "entries.product.ui.order": "In den Warenkorb",
    "entries.product.ui.notOrderable": "Bitte wählen Sie eine Variante aus.",
    "entries.product.ui.notInStock": "Das Produkt kann zur Zeit nicht bestellt werden.",
    "entries.entry.ui.products.delivery.red": "Produkt zur Zeit nicht lieferbar",
    "entries.entry.ui.products.delivery.yellow": "Produkt im Zulauf. Eventuell längere Lieferzeit.",
    "entries.entry.ui.products.delivery.green": "Produkt in ausreichender Stückzahl auf Lager",
    "entries.entry.ui.products.deliveryTime.month": "Produkt voraussichtlich wieder lieferbar in 1 Monat.",
    "entries.products.ui.combinationNotAvailable": "Produkt in dieser Kombination nicht verfügbar",
    "product.ui.content": "Inhalt",
    "product.ui.ingredients": "Inhalt",
    "product.ui.ingredientsFood": "Zutaten",
    "product.ui.nonPhysical": "Elektronisches Produkt zum Download",
    "entries.product.ui.overview": "Übersicht",
    "entries.product.per": "Je",
    "entries.product.perServing": "Je Portion",
    "entries.product.reference": "Referenzmenge",
    "entries.product.nutritionTable": "Nährwertangaben",
    "entries.product.nutrientsTable": "Nährstoffe, Mineralien und Vitamine",
    "entries.product.bio": "Zutaten aus kontrolliert biologischem Anbau",
    "entries.product.ui.noPrize": "Bitte fragen Sie für den Preis Ihren Ansprechpartner.",
    "entries.product.ui.prizeDecimalPoint": ",",
    "entries.product.ui.prizeFrom": "ab ",
    "entries.product.ui.item": "Stück",
    "entries.product.ui.prizePerItem": "Preis pro Stück ",
    "entries.product.ui.size": "Größe",
    "entries.product.ui.required": "Nur mit ",
    "entries.product.ui.suitableFor": "Passend für",
    "entries.product.ui.suitableForAttachments": "Passend an folgenden Anbaugeräten",
    "entries.product.ui.suitableForCarriers": "Passend für folgende Trägerfahrzeuge",
    "entries.product.ui.title": "Produkt",
    "entries.product.ui.variant": "Variante/Bundle",
    "entries.product.ui.variantNotOrderable": "Die ausgewählte Variante kann nicht bestellt werden",
    "entries.product.ui.inVariants": "bei Produktvarianten",
    "entries.product.ui.bundleparts": "Bestehend aus",
    "entries.progress.title": "So verbesserst du deinen Eintrag",
    "entries.tabbar.basics": "Basisdaten",
    "entries.tabbar.entry": "Eintragdaten",
    "entries.tabbar.gallery": "Bilder",
    "entries.tabbar.layout": "Layout",
    "entries.tabbar.openingHours": "Öffnungszeiten",
    "entries.tabbar.order": "Bestellinformationen",
    "entries.tabbar.tooltip.entryNotSavedDetail": "Eintragdaten können erst bearbeitet werden, wenn der Eintrag mit Titel gespeichert wurde.",
    "entries.tabbar.tooltip.entryNotSavedLayout": "Layoutdaten können erst bearbeitet werden, wenn der Eintrag mit Titel gespeichert wurde.",
    "entries.types.poi": "Poi",
    "entries.types.standard": "Standard",
    "entries.ui.groupDetailName": "Name für Gruppeneinträge",
    "entries.ui.groupName": "Name für die Gruppen (Region)",
    "entryTypes.entry": "Eintrag",
    "entryTypes.event": "Veranstaltung",
    "entryTypes.poi": "Veranstaltungsort",
    "errors.blank": "{{description}} muss ausgefüllt sein",
    "errors.description": "Dieses Feld",
    "errors.email": "Bitte tragen Sie eine gültige {{description}}-Adresse ein",
    "errors.empty": "{{description}} darf nicht leer sein",
    "errors.exclusion": "Diese {{description}} ist bereits vergeben",
    "errors.inclusion": "Diese {{description}} ist nicht in der Auswahl enthalten",
    "errors.invalid": "{{description}} ist ungültig",
    "errors.mandatory": "{{field}} muss ausgefüllt sein",
    "errors.mandatoryCheckbox": "Muss bestätigt werden.",
    "errors.mandatorySubmit": "Sie können das Formular erst absenden, wenn alle Pflichfelder ausgefüllt sind.",
    "errors.slug": "Slug darf nur Kleinbuchstaben, Zahlen und \"-\" enthalten",
    "forms.ui.deleteFile": "Datei löschen",
    "forms.ui.formErr": "Fehler beim verschicken des Formulars",
    "forms.ui.formSent": "Formular abgesendet",
    "forms.ui.noForm": "Kein Formular zugewiesen",
    "forms.ui.invalidMail": "Bitte geben Sie eine gültige E-Mail Adresse an.",
    "forms.ui.isSubmitted": "Das Formular wurde versendet. Wir setzen uns mit Ihnen so schnell wie möglich in Verbindung.",
    "forms.ui.missingFields": "Folgende Felder müssen ausgefüllt werden:",
    "forms.ui.uploadSuccess": "{{file}} wurde hochgeladen",
    "forms.ui.fieldBuilder.street": "Straße",
    "forms.ui.fieldBuilder.houseNumber": "Nr.",
    "forms.ui.fieldBuilder.zip": "PLZ",
    "forms.ui.fieldBuilder.city": "Stadt",
    "forms.ui.fieldBuilder.country": "Land",
    "forms.ui.fieldBuilder.firstName": "Vorname",
    "forms.ui.fieldBuilder.lastName": "Nachname",
    "forms.ui.privacyRead": "Die Datenschutzerklärung habe ich zur Kenntnis genommen",
    "forms.ui.privacy": "Datenschutzerklärung",
    'forms.ui.submitted': 'Eingang',
    'forms.ui.submissionTime': 'Eingangszeitpunkt',
    "jobs.ui.employmentType": "Art der Stelle",
    "jobs.ui.employmentType.FULL_TIME": "Vollzeit",
    "jobs.ui.employmentType.PART_TIME": "Teilzeit",
    "jobs.ui.employmentType.APPRENTICE": "Ausbildung",
    "jobs.ui.employmentType.INTERN": "Praktikum",
    "jobs.ui.employmentType.CONTRACTOR": "Freiberufliche Tätigkeit",
    "jobs.ui.employmentType.TRAINEE": "Trainee, berufbegleitendes Studium",
    "jobs.ui.employmentType.VOLUNTEER": "Ehrenamtlich",
    "jobs.ui.employmentType.OTHER": "",
    "jobs.ui.location": "Stelle verfügbar bei",
    "jobs.ui.role.male": "M",
    "jobs.ui.role.female": "W",
    "jobs.ui.role.divers": "D",
    "jobs.ui.startDateNow": "Ab Sofort",
    "jobs.ui.startFrom": "Ab ",
    "media.documents.search.noResult": "Bitte geben sie eine Seriennummer ein oder wählen sie eine Kategorie.",
    "media.documents.search.placeholder": "Nach Geräte-Seriennummer oder Typnummer suchen",
    "media.documents.search.reset": "Suchergebnis zurücksetzen",
    "media.ui.copyright": "Copyright",
    "media.ui.cropFormat": "Formatvorgabe",
    "media.ui.cropImage": "Zuschneiden",
    "media.ui.cropImageDuplicate": "Duplizieren und zuschneiden",
    "media.ui.description": "Beschreibung",
    "media.ui.filename": "Dateiname",
    "media.ui.imageSaved": "Bild gespeichert",
    "media.ui.processingImage": "Bild wird zugeschnitten, bitte warten",
    "media.ui.resetCrop": "Zuschnitt zurücksetzen",
    "media.ui.resetImage": "Zurück zum Original",
    "media.ui.rotateImage": "Bild 90° im Uhrzeigersinn drehen",
    "media.ui.title": "Titel",
    "name": "Name",
    "openingHours.additionalInfo": "Informationen zu den Öffnungszeiten",
    "openingHours.addOpening": "Öffnungszeit hinzufügen",
    "openingHours.date.day.di": "Di",
    "openingHours.date.day.do": "Do",
    "openingHours.date.day.fr": "Fr",
    "openingHours.date.day.mi": "Mi",
    "openingHours.date.day.mo": "Mo",
    "openingHours.date.day.sa": "Sa",
    "openingHours.date.day.so": "So",
    "openingHours.dateS": "Datum",
    "openingHours.delete": "Löschen",
    "openingHours.from": "von",
    "openingHours.options": "Bereich",
    "openingHours.time": "Uhrzeit",
    "openingHours.to": "bis",
    "openingHours.type": "Art der Öffnungszeit",
    "opt-in.error-can-not-update-member": "Die Änderungen an den Mitglieds Daten für {{email}} konnten nicht durchgeführt werden.",
    "opt-in.error-list-does-not-exist": "Es wurde keine Liste für die angegebenen Daten gefunden.",
    "opt-in.error-list-does-not-need-validation": "Die angegebene liste hat keinen opt-int.",
    "opt-in.error-member-not-in-list": "Es wurde kein Mitglied einer Liste für die angegebenen Daten gefunden.",
    "opt-in.success-member-verified": "Die {{email}} wurde zur {{listname}} Liste hinzugefügt.",
    "opt-out.error-can-not-update-member": "Die Änderungen an den Mitglieds Daten für {{email}} konnten nicht durchgeführt werden.",
    "opt-out.error-list-does-not-exist": "Es wurde keine Liste für die angegebenen Daten gefunden.",
    "opt-out.error-list-does-not-have-opt-out": "Die angegebene Liste hat keinen opt-out.",
    "opt-out.error-member-not-in-list": "Es wurde kein Mitglied einer Liste für die angegebenen Daten gefunden.",
    "opt-out.success-membership-terminated": "Die {{email}} wurde von der {{listname}} entfernt.",
    "password.errors.not-secure-enough": "Wir empfehlen ein Passwort mit einer Länge von mindestens 8 Zeichen, Groß- und Kleinbuchstaben, Zahlen und Sonderzeichen ($@$!%*#?&).",
    "password.errors.password-and-conf-do-not-match": "Die beiden Passwörter müssen identisch sein.",
    "password.quality.0": "Sehr Schlecht",
    "password.quality.1": "Schlecht",
    "password.quality.2": "Ausreichend",
    "password.quality.3": "Gut",
    "password.quality.4": "Sehr Gut",
    "profile.entries.copycats": "Einträge, die {{userName}} auch machen will",
    "profile.entries.donates": "Einträge, die {{userName}} unterstützt",
    "profile.entries.entries": "Einträge von {{userName}}",
    "profile.entries.follows": "Einträge, denen {{userName}} folgt",
    "profile.entries.joins": "Einträge, bei denen {{userName}} mitmacht",
    "profile.marketplace.fields.energyRequired": "Bedarf an Strom",
    "profile.marketplace.fields.livingAnimals": "Mitnahme von lebenden Tieren",
    "profile.marketplace.fields.loadingUnloading": "Bei An-/Abfahrt notwendig",
    "profile.marketplace.fields.maxRange": "Für mein Angebot bin ich bereits soviele km zu fahren",
    "profile.marketplace.fields.placeRequired": "Anforderungen an den Stellplatz",
    "profile.marketplace.fields.securityRequired": "Bedarf an Security",
    "profile.marketplace.fields.shows": "Programmangebot",
    "profile.marketplace.fields.spaceRequired": "Platzbedarf in m²",
    "profile.marketplace.fields.waterRequired": "Bedarf an Wasser in m³",
    "profile.marketplace.new": "Neuer Angebot oder Bedarf",
    "profile.marketplace.offer.delete": "Löschen",
    "profile.marketplace.offer.description": "Beschreibung",
    "profile.marketplace.offer.descriptionPlaceholder": "Aussagekräftige Beschreibung, damit ein anderer Benutzer etwas damit anfangen kann.",
    "profile.marketplace.offer.new": "Neues Angebot anlegen",
    "profile.marketplace.offer.relatedEntry": "Zugeordneter Eintrag",
    "profile.marketplace.offer.tags": "Tags für Angbebot/Anfrage (wird damit besser gefunden)",
    "profile.marketplace.offer.title": "Ich biete an",
    "profile.marketplace.request.delete": "Anfrage löschen",
    "profile.marketplace.request.new": "Neue Anfrage anlegen",
    "profile.marketplace.request.missingCredentials": "Sie können erst eine Anfrage starten, wenn Sie mindestens einen Standort und eine Messstelle angelegt haben.",
    "profile.marketplace.request.title": "Ich benötige",
    "profile.messages.approve": "Bestätigen",
    "profile.messages.as": "als",
    "profile.messages.commentPre": "hat",
    "profile.messages.commentSuff": "kommentiert",
    "profile.messages.decided": "dazu entschieden",
    "profile.messages.deletedUser": "Gelöschter Benutzer",
    "profile.messages.donateDream": "Vielen Dank, dass du diesen Entryer mit Rat und Tat unterstützt hast, seinen Eintrag zu realisieren. Er erhält umgehend deine Nachricht und wird sich hoffentlich bei dir melden und deine Hilfe akzeptieren.",
    "profile.messages.dreamer": "Einträger",
    "profile.messages.dreamToo": "auch zu träumen",
    "profile.messages.enter": "beigetreten",
    "profile.messages.favoriteDream": "Du folgst diesen Eintrag jetzt.",
    "profile.messages.follow": "folgt",
    "profile.messages.has": "hat sich",
    "profile.messages.is": "ist",
    "profile.messages.join": "joinen",
    "profile.messages.joinDream": "Du willst diesen Eintrag mitträumen.",
    "profile.messages.likeDream": "Du magst diesen Eintrag jetzt.",
    "profile.messages.sendText": "Ihre Nachricht wurde versendet.",
    "profile.messages.shareDream": "Du hast diesen Eintrag erfolgreich geteilt.",
    "profile.messages.supporter": "Unterstützer",
    "profile.messages.want": "möchte",
    "profile.messages.youAre": "Sie sind",
    "profile.messages.youWant": "Sie möchten",
    "profile.newsletter.notSubscribed": "abonnieren",
    "profile.newsletter.notSubscribedAny": "Sie haben noch keine Newsletterliste abonniert.",
    "profile.newsletter.privacyInformation": "Durch einen Klick auf «abonnieren» erklärst du dich damit einverstanden, dass wir dir die künftigen Newsletter dieses Abonnements zusenden (Opt-In). Du kannst den Newsletter jederzeit wieder abbestellen, indem du erneut auf «abonnieren» klickst (Opt-Out).",
    "profile.newsletter.subscribed": "abonniert",
    "profile.press.articleDetail": "Artikeldetail",
    "profile.press.downloadImage": "Bild herunterladen",
    "profile.press.downloadImages": "Bilder herunterladen",
    "profile.press.downloadText": "Text exportieren",
    "profile.press.filterAuthor": "Nach Autor filtern",
    "profile.press.hide": "ausblenden",
    "profile.press.show": "einblenden",
    "profile.progress.title": "So verbesserst du dein Profil",
    "register.ui.addressData": "Adressdaten",
    "register.ui.addressInfo": "Sie müssen ihre persönliche Adresse nicht eingeben. Diese führt aber zu korrekten Routen- und Entfernungskalkulationen.",
    "register.ui.companyData": "Ihre Firmenangaben",
    "register.ui.companyDataMandatory": "Sie müssen Ihren Firmennamen angeben",
    "register.ui.emptyFirstName": "Sie müssen Ihren Vornamen angeben",
    "register.ui.emptyLastName": "Sie müssen Ihren Nachnamen angeben",
    "register.ui.emptyMail": "Sie müssen eine gültige E-Mail-Adresse angeben",
    "register.ui.emptyUserName": "Sie müssen einen Benutzernamen angeben",
    "register.ui.language": "Sprache",
    "register.ui.loginData": "Anmeldedaten",
    "register.ui.password": "Passwort",
    "register.ui.passwordRepeat": "Passwort wiederholen",
    "register.ui.personalData": "Ihre persönlichen Daten",
    "register.ui.privacyStatement": "Ich willige ein, dass meine personenbezogenen Daten gespeichert werden. Eine Weitergabe meiner o.g. personenbezogenen Daten an Dritte erfolgt nicht. Diese datenschutzrechtliche Einwilligung kann ich jederzeit mit Wirkung für die Zukunft widerrufen.",
    "register.ui.takenEmail": "Diese Email wurde bereits von einem Benutzer angegeben.",
    "register.ui.takenUserName": "Dieser Benutzername ist bereits vergeben",
    "register.ui.timezone": "Zeitzone",
    "register.ui.typeMinimum": "Sie müssen mindestens einen Typ auswählen",
    "register.ui.usernameInfo": "Wichtig: Das ist Ihr Benutzername für die Anmeldung",
    "register.ui.oauthInfo": "Sie werden zur Loginseite des FRM weitergeleitet, um sich mit Ihren Benutzerdaten anzumelden und kehren anschließend wieder auf die Webseite zurück.",
    "search.allDistances": "Alle Entfernungen",
    "search.andFilter": " und Filtereinstellungen",
    "search.closeTags": "Tags ausblenden",
    "search.for": "nach",
    "search.zipCountries": "Postleitzahlen aus",
    "search.resetPosition": "Position zurücksetzen",
    "search.geoPositionBrowser": "Position durch Browser ermitteln",
    "search.geoPositionFault": "Positionsermittlung durch den Browser ist nicht möglich",
    "search.geoPositionTitle": "Legen Sie die Position für eine Umkreissuche fest",
    "search.selectedFilters": "Ausgewählte Filter",
    "search.selectFilters": "Suche nach Kategorien filtern",
    "search.hits": "Treffer",
    "search.openTags": "Tags einblenden",
    "search.removeDatefilter": "Datumfilter entfernen",
    "search.resulted": "ergab",
    "search.resultType.category": "Kategorie",
    "search.resultType.entry": "Eintrag",
    "search.resultType.event": "Veranstaltung",
    "search.resultType.group": "Gruppe",
    "search.resultType.page": "Seite",
    "search.resultType.person": "Person",
    "search.resultType.post": "Beitrag",
    "search.resultType.product": "Produkt",
    "search.resultType.article": "Artikel",
    "search.resultType.job": "Job",
    "search.resultType.recipe": "Rezept",
    "search.search": "Suche",
    "search.result": "Ergebnis",
    "search.results": "Ergebnissen",
    "search.searchBarPlaceholder": "Suche...",
    "search.searchBarPlaceholderEntries": "Suche nach Händlername oder Händleradresse. ",
    "search.searchBarPlaceholderEntriesPosition": "Umkreissuche mit eigenen Standort (z.B. Stadt)…",
    "search.searchBarPlaceholderEntriesPositionZip": "…oder PLZ",
    "search.searchBarPlaceholderGeoSearch": "Geben Sie Ihre Adresse ein...",
    "search.searchBarPlaceholderMobile": "Suche...",
    "search.searchBarPlaceholderProducts": "Suche Produkte",
    "search.searchBarPositionTooltip": "Position ermitteln oder Standort mit Adresse eingeben",
    "search.showAll": "Alles anzeigen",
    "search.globalSearch": "Suchseite",
    "social.comment": "Kommentar",
    "social.Comment": "Kommentar",
    "social.CopycatJoiner": "Träumt deinen Eintrag",
    "social.dream.donateAmount": "Mit Geldbetrag unterstützen",
    "social.dream.donateDreampoints": "Mit Dreampoints unterstützen",
    "social.dream.donateInfo": "Die Nachricht ist privat und kann nur vom Eintrageigentümer gelesen werden.",
    "social.dream.donateTip": "Mit Rat und Tat unterstützen",
    "social.dream.done": "DONE",
    "social.dream.edit": "Eintrag bearbeiten",
    "social.dream.findDreams": "Finde hier kleine und große Einträge und mache Deinen Eintrag wahr und/oder hilf anderen ihre Einträge zu realisieren.",
    "social.dream.followedDreams": "Einträgen denen du folgst",
    "social.dream.giveEmail": "Meine E-Mail-Adresse weitergeben",
    "social.dream.givePhone": "Meine Telefonnummer weitergeben",
    "social.dream.joinerApproved": "Du machst bei diesem Eintrag mit",
    "social.dream.joinerButton": "Bei diesem Eintrag mitmachen",
    "social.dream.joinerButtonMobile": "Mitmachen",
    "social.dream.joinerDream": "Gruppentraum",
    "social.dream.joinerInfo": "Wenn der Eintrageigentümer dein Mitmachen bestätigt, wird dieser Eintrag zum Gruppentraum, bei dem du weitergehende Rechte hast.",
    "social.dream.joinerMessage": "Gib hier deine Nachricht für den Eintrageigentümer ein:",
    "social.dream.joinerPending": "Der Eintrageigentümer muss dein Mitmachen noch bestätigen",
    "social.dream.joinerSupport": "Du unterstützt diesen Eintrag",
    "social.dream.joinerSupportButton": "Diesen Eintrag unterstützen",
    "social.dream.joinerSupportButtonMobile": "Unterstützen",
    "social.dream.joinerSupportInfo": "Wir arbeiten laufend an Verbesserungen, wie z.B. mit Geld oder Entrypoints zu unterstützen. Stay tuned.",
    "social.dream.joinerSupportPending": "Der Eintrageigentümer muss deine Unterstützung noch erlauben",
    "social.dream.member": "Mitglied",
    "social.dream.members": "Mitglieder",
    "social.dream.meToo": "Will ich auch machen",
    "social.dream.meTooInfo": "Zeige dem Eintrageigentümer, dass du den Eintrag ganz toll findest und diesen Eintrag auch machen willst.",
    "social.dream.noFollow": "Du folgst keinem Eintrag!",
    "social.dream.noJoiner": "Du bist noch kein Mitglied eines Gruppentraumes",
    "social.dream.noLikes": "Dir gefällt noch kein Eintrag!",
    "social.dream.noSupport": "Du unterstützt keinen Eintrag",
    "social.dream.registerFirstText": "Um bei einem Eintrag miträumen zu können, um den Eintrag zu unterstützen oder anzuzeigen, dass Du diesen Eintrag auch machen möchtest, musst Du angemeldet sein. Die Anmeldung bei share your entry geht schnell und ist kostenlos. Klicke einfach auf den \"Anmelden\" Button.",
    "social.dream.registerFirstTitle": "Mitträumen, unterstützen, auch machen?",
    "social.dream.settings": "Eintrageinstellungen",
    "social.dream.shareTitle": "Share the dream ",
    "social.dream.similar": "Ähnliche Einträge",
    "social.dreamline.addImageToDream": "Dieses Bild zu deinem Eintrag hinzufügen",
    "social.dreamline.addMedia": "Bild / Video hinzufügen",
    "social.dreamline.allActivities": "Alle Dreamline-Aktivitäten",
    "social.dreamline.anonymous": "Anonym",
    "social.dreamline.commentButton": "Kommentar",
    "social.dreamline.comments": "schreibt:",
    "social.dreamline.depublished": "Dieser Kommentar wurde wegen nicht angebrachtem Verhalten entfernt.",
    "social.dreamline.dreampointsInfo": "Das sind die Dreampoints des Eintrags. Mehr zu Dreampoints findest Du in Deinem Profil.",
    "social.dreamline.fewDreampoints": " hat nur wenige Dreampoints. Kommentiere, like oder share den Eintrag.",
    "social.dreamline.filterBySteps": "Nach Steps filtern",
    "social.dreamline.filterComment": "Kommentaren",
    "social.dreamline.filteredBy": "Aktivitäten gefiltert nach ",
    "social.dreamline.filteredBySteps": "Nach Steps gefiltert",
    "social.dreamline.filterFavorite": "Follows",
    "social.dreamline.filterLike": "Likes",
    "social.dreamline.filterShare": "Shares",
    "social.dreamline.filterStep": "Steps",
    "social.dreamline.flagged": "Dieser Beitrag wurde gemeldet",
    "social.dreamline.isComment": " kommentiert",
    "social.dreamline.isCopycatJoiner": "auch träumen",
    "social.dreamline.isFavorite": "",
    "social.dreamline.isFirstUpdate": " erstellt",
    "social.dreamline.isJoiner": "",
    "social.dreamline.isLike": "",
    "social.dreamline.isShare": " geteilt",
    "social.dreamline.isStep": " einen neuen Schritt gemacht",
    "social.dreamline.isUpdate": " aktualisiert",
    "social.dreamline.lastEntry": "Letzte Eintragaktualisierung",
    "social.dreamline.locked": "Dieser Inhalt ist nur für Mitträumer sichtbar",
    "social.dreamline.lockedCTA": "Jetzt bei deisem Eintrag mitmachen",
    "social.dreamline.noMessages": "Dieser Eintrag hat noch keine Aktivität. Schreibe jetzt den ersten Kommentar.",
    "social.dreamline.noMessagesFiltered": "Keine Aktivität mit diesen Filtereinstellungen bei diesem Eintrag.",
    "social.dreamline.nowFollower": "folgt diesem Eintrag!",
    "social.dreamline.nowJoiner": "ist nun ein Unterstützer",
    "social.dreamline.nowLikes": "liked diesen Eintrag nun!",
    "social.dreamline.nowSharing": "hat diesen Eintrag geteilt!",
    "social.dreamline.onlyJoinerVisible": "Nur für Mitträumer/Unterstützer sichtbar",
    "social.dreamline.prefixComment": "hat diesen Eintrag vor ",
    "social.dreamline.prefixCopycatJoiner": "möchte diesen Eintrag seit",
    "social.dreamline.prefixFavorite": "folgt diesem Eintrag seit ",
    "social.dreamline.prefixJoiner": "unterstützt diesen Eintrag seit ",
    "social.dreamline.prefixLike": "mag diesen Eintrag seit ",
    "social.dreamline.prefixShare": "hat diesen Eintrag vor ",
    "social.dreamline.prefixStep": "hat vor ",
    "social.dreamline.prefixUpdate": "hat diesen Eintrag vor ",
    "social.dreamline.profile": "Profil",
    "social.dreamline.provideImage": "Ich stelle dieses Bild dem Eintragbesitzer zur Verfügung",
    "social.dreamline.resetFilter": "Filter zurücksetzen",
    "social.dreamline.sendComment": "Abschicken",
    "social.dreamline.sharePart": "Teilen",
    "social.dreamline.toggleStream": "Kommentare",
    "social.dreamline.toggleStreamSingle": "Kommentar",
    "social.dreamline.writeComment": "Kommentar schreiben...",
    "social.Favorite": "Follow",
    "social.follow": "Follow",
    "social.Follow": "Follow",
    "social.hasLiked": "Du magst diesen Eintrag bereits.",
    "social.join": "Join",
    "social.Join": "Join",
    "social.Joiner": "Mitträumen",
    "social.like": "Like",
    "social.Like": "Like",
    "social.report.headlinePrefix": "Sie sind dabei",
    "social.report.headlineSuffix": "zu melden.",
    "social.report.login": "Sie müssen angemeldet sein, um den Beitrag zu melden.",
    "social.report.personSuffix": "s",
    "social.report.reportOther": "Anderer anstößiger Inhalt",
    "social.report.reportScam": "Falsche Darstellung, Mobbing.",
    "social.report.reportSex": "Sexueller Inhalt",
    "social.report.reportSpam": "Dies ist Spam",
    "social.report.reportViolence": "Gewaltdarstellung",
    "social.report.send": "Meldung absenden",
    "social.report.title": "Melden",
    "social.share": "Share",
    "social.Share": "Share",
    "syd.ui.dreamerCommercial": "B2B Standard",
    "syd.ui.userCommercial": "B2B Dreamer",
    "user.anonymous-user-name": "",
    "user.company.addUser": "Weitere Benutzer anlegen für ",
    "user.company.inviteUser": "Einladungsmail verschicken",
    "user.company.userIsInvited": "Einladungsmail wurde verschickt",
    "user.company.userIsActive": "Benutzer ist aktiv",
    "user.company.userIsNotActive": "Benutzer ist noch nicht aktiviert",
    "user.company.additionalWebsite": "Weitere Webseite des Unternehmens (nur Domain ohne https://)",
    "user.company.companyName": "Firmenname",
    "user.company.contactFor": "Kontaktdaten für ",
    "user.company.contactForDefault": "allgemeinen Kontakt",
    "user.company.copyContact": "Kontaktdaten aus Firmenangaben für den Eintrag übernehmen",
    "user.company.email": "E-Mail zur Kontaktaufnahme",
    "user.company.facebook": "Facebook-Account des Unternehmens (nur Accountname)",
    "user.company.fax": "Telefaxnummer für schriftliche Kontaktaufnahme",
    "user.company.google": "Google+-Account des Unternehmens (nur Accountname)",
    "user.company.instagram": "Instagram-Account des Unternehmens (nur Accountname)",
    "user.company.linkedin": "LinkedIn-Account des Unternehmens (nur Accountname)",
    "user.company.newCustomerAccount": "Ein Business-Konto anlegen",
    "user.company.noCustomerValidation": "Ihr Business-Konto wurde noch nicht freigeschaltet.",
    "user.company.noCustomerYet": "Ihrer Registrierung wurde noch kein Business-Konto zugewiesen.",
    "user.company.notAllowed": "Sie können keine Änderungen vornehmen, da Ihr Benutzerstatus ({{type}}) dies nicht zulässt.",
    "user.company.phone": "Telefonnummer zur Kontaktaufnahme",
    "user.company.pinterest": "Pinterest-Account des Unternehmens (nur Accountname)",
    "user.company.remarks": "Anmerkungen",
    "user.company.registerNumber": "Handelsregisternummer",
    "user.company.registerAuth": "Amtsgericht",
    "user.company.acerCode": "ACER-Code Ihrer Energieversorgung",
    "user.company.taxNumber": "Steuernummer",
    "user.company.type.default": "Standard",
    "user.company.type.procurator": "Prokurist",
    "user.company.type.manager": "Geschäftsführer",
    "user.company.twitter": "Twitter-Unternehmensaccount (nur Accountname ohne @)",
    "user.company.ustid": "UStID",
    "user.company.ustid.notValid": "Bitte eine gültige Umsatzsteuer-Identifikatiosnummer angeben<br>(falls vorhanden)",
    "user.company.website": "Webseite des Unternehmens (nur Domain ohne https://)",
    "user.company.xing": "Xing-Account des Unternehmens (nur Accountname)",
    "user.company.youtube": "Youtube-Account des Unternehmens (nur Accountname)",
    "user.message.after-save-error": "Ihre Änderungen konnten nicht gespeichert werden.",
    "user.message.after-save-success": "Ihre Änderungen wurden gespeichert.",
    "user.message.can-not-login": "Mit diesen Benutzer- und Passworteingaben können wir dich nicht anmelden! Ist der Benutzername richtig geschrieben? Das Passwort richtig eingegeben? Eventuell ist bei der Anmeldung auch etwas schief gegangen.",
    "user.message.can-not-logout": "Wir konnten Sie nicht abmelden!",
    "user.message.conflict": "Dieser Benutzername existiert bereits, wählen Sie einen anderen!",
    "user.message.fileExistsError": "Upload fehlgeschlagen. Datei existiert bereits",
    "user.message.fileSizeError": "Upload fehlgeschlagen. Datei ist zu groß",
    "user.message.loginFailureBottom": "Vielleicht hast du noch gar keinen Entryer-Account?",
    "user.message.loginFailureTop": "Ist dein Benutzername richtig geschrieben? Hast du dein Passwort richtig eingegeben?",
    "user.message.registration-error": "Bei Ihrer Registrierung ist ein Fehler aufgetreten!",
    "user.message.registration-success": "Vielen Dank für Ihre Registrierung",
    "user.message.unknownError": "Unbekannter Fehler beim Hochladen der Datei",
    "user.message.upload-success": "Upload erfolgreich",
    "user.message.verify-error": "Ihre E-Mail-Adresse konnte nicht überprüft werden. Bitte kontaktieren sie den Administrator.",
    "user.message.verify-success": "Ihre E-Mail-Adresse wurde bestätigt. Sie können sich jetzt anmelden: ",
    "user.ui.accountGreeting": "Hallo, ",
    "user.ui.accountMenu": "Startseite und Informationen",
    "user.ui.accountMenuActivities": "Aktivitäten",
    "user.ui.accountMenuCompanySettings": "Firmenangaben",
    "user.ui.accountMenuEntries": "Meine Einträge",
    "user.ui.accountMenuEntryPre": "Einträge, ",
    "user.ui.accountMenuEvents": "Meine Veranstaltungen",
    "user.ui.accountMenuFavorites": "Meine Favoriten",
    "user.ui.accountMenuFollows": "Einträge, denen ich folge",
    "user.ui.accountMenuImages": "Bilder/Videos/Dokumente",
    "user.ui.accountMenuJoin": "Meine Gruppenträume",
    "user.ui.accountMenuJobs": "Meine Jobs",
    "user.ui.accountMenuJobApplications": "Bewerbungen",
    "user.ui.accountMenuLikes": "Einträge, die ich mag",
    "user.ui.accountMenuMeasuring": "Meine Messstandorte und Daten",
    "user.ui.accountMenuMessages": "Nachrichten",
    "user.ui.accountMenuNewsletter": "Meine Newsletter Abos",
    "user.ui.accountMenuPoi": "Meine Standorte",
    "user.ui.accountMenuPress": "Meine Artikel",
    "user.ui.accountMenuProducts": "Meine Produkte",
    "user.ui.accountMenuProfile": "Benutzerprofil",
    "user.ui.accountMenuRatings": "Bewertungen",
    "user.ui.accountMenuRegistrationData": "Anmeldedaten",
    "user.ui.accountMenuReviews": "Rezensionen",
    "user.ui.accountMenuSettings": "Einstellungen",
    "user.ui.accountMenuSkillsOffer": "Ich biete...",
    "user.ui.accountMenuSkillsRequest": "Ich brauche...",
    "user.ui.accountMenuOffer": "Meine Angebote",
    "user.ui.accountMenuRequest": "Meine Anfragen",
    "user.ui.accountMenuSkillsSearch": "Gesuche und Angebote",
    "user.ui.accountMenuSubscriptions": "Abonnements",
    "user.ui.accountMenuSubscritions": "Mein Abonnement",
    "user.ui.accountMenuSupport": "Unterstützte Einträge",
    "user.ui.accountMenuSupports": "Einträge die ich Unterstütze",
    "user.ui.accountMenuTender": "Ausschreibungen",
    "user.ui.accountMenuOrders": "Meine Bestellungen",
    "user.ui.accountNoMessages": "Sie haben bis jetzt noch keine Nachrichten erhalten.",
    "user.ui.accountProgressbar": "Dein Profil ist zu {{value}}% komplett",
    "user.ui.accountProgressbarEntry": "Dein Eintrag ist zu {{value}}% komplett",
    "user.ui.accountSettings": "Weitere Einstellungen",
    "user.ui.accountCookieSettings": "Klicken Sie hier, um Ihre Einstellungen anzupassen.",
    "user.ui.accountCookieEssential": "Essentiell",
    "user.ui.accountCookieAnalysis": "Analysedaten",
    "user.ui.accountCookieSocial": "Soziale Medien",
    "user.ui.accountCookieReviews": "Bewertungen",
    "user.ui.accountToolbarLogin": "Anmelden",
    "user.ui.accountToolbarLoginTooltip": "Kostenlos anmelden und loslegen",
    "user.ui.accountToolbarLogout": "Abmelden",
    "user.ui.adminPreview": "Vorschau",
    "user.ui.backToLogin": "Zurück zur Anmeldenseite",
    "user.ui.birthday": "Geburtstag",
    "user.ui.cannotLogin": "Sie können sich nicht anmelden? Klicken Sie hier, um Ihr Passwort zurückzusetzen.",
    "user.ui.changePassword": "Ich möchte mein Passwort ändern",
    "user.ui.changeUserGroup": "Anderes Abonnement",
    "user.ui.city": "Stadt",
    "user.ui.companyName": "Firmenname",
    "user.ui.companyNameTooltip": "Wichtig: Unter diesen Firmennamen erscheint dann auch Ihr Eintrag",
    "user.ui.country": "Land",
    "user.ui.createNewAccount": "Ein neues Benutzerkonto erstellen",
    "user.ui.dataDelete": " weiteren Einträgen benutzt. Möchtest Du die Datei endgültig löschen? Überall, wo diese Datei verwendet wird, wird diese durch einen Platzhalter ersetzt.",
    "user.ui.dataUsage": "Die Datei wird in ",
    "user.ui.dataUsageNone": "Die Datei wird noch nicht verwendet. Möchtest Du die Datei endgültig löschen?",
    "user.ui.deleteImage": "Bild löschen",
    "user.ui.description": "Geben Sie hier eine allgemeine Beschreibung zur Person oder zum Unternehmen ein",
    "user.ui.documents": "Dokumente",
    "user.ui.documentSearch": "Dokumentensuche",
    "user.ui.download": "{{title}} herunterladen",
    "user.ui.email": "E-Mail-Adresse",
    "user.ui.fillOutForm": "Zur Registrierung müssen alle Pflichtfelder vollständig ausgefüllt sein",
    "user.ui.firstName": "Vorname",
    "user.ui.academicTitle": "Akademischer Titel",
    "user.ui.gender": "M/W",
    "user.ui.genderFemale": "weiblich",
    "user.ui.genderFemaleSalutation": "Frau",
    "user.ui.genderMale": "männlich",
    "user.ui.genderMaleSalutation": "Herr",
    "user.ui.genderDiverse": "divers",
    "user.ui.genderThird": "keine Angabe",
    "user.ui.housenumber": "Nr.",
    "user.ui.iAmCustomer": "Ich möchte einen B2B Account anlegen",
    "user.ui.internalInfo": "Es liegen Nachrichten für Sie vor. Klicken Sie hier.",
    "user.ui.lastLogin": "Letzte Anmeldung am ",
    "user.ui.languageAvailable": "In Ihrer Sprache verfügbar",
    "user.ui.languageMissing": "Nur verfügbar in",
    "user.ui.lastName": "Nachname",
    "user.ui.loginButton": "Anmelden",
    "user.ui.loginTitle": "Benutzeranmeldung",
    "user.ui.memberSince": "Mitglied seit ",
    "user.ui.mobileNumber": "Mobilnummer",
    "user.ui.myAddedStorage": "Zur Verfügung gestellte Bilder",
    "user.ui.myStorage": "Mein Verzeichnis",
    "user.ui.noRegisterMessage": "Für ein Benutzerkonto wenden Sie sich bitte an die angegebenen Kontaktadressen. Sie können sich nicht selbst als Benutzer registrieren. ",
    "user.ui.oldPassword": "Bisheriges Passwort",
    "user.ui.password": "Passwort",
    "user.ui.phoneType": "Telefon",
    "user.ui.phoneTypeMobile": "Mobil",
    "user.ui.phoneTypeLandline": "Festnetz",
    "user.ui.pleaseSelect": "Bitte auswählen",
    "user.ui.pleaseSelectSalutation": " Bitte Anrede auswählen",
    "user.ui.registerButton": "Jetzt Benutzerkonto einrichten",
    "user.ui.registerMessage": "Jetzt anmelden und sofort loslegen. Es geht schnell und einfach.",
    "user.ui.registerTitle": "Neu bei uns?",
    "user.ui.requestPasswordReset": "Neues Passwort anfordern und altes Passwort löschen",
    "user.ui.requestPasswordSuccess": "In Kürze sollten Sie eine Email mit einem Reset-Link erhalten.",
    "user.ui.requestPasswordError": "Unbekannter Fehler beim zurücksetzen des Passworts.",
    "user.ui.resetPassword": "Passwort neu setzen",
    "user.ui.setPassword": "Passwort setzen",
    "user.ui.resetPasswordPage": "Setzen Sie Ihr Passwort neu",
    "user.ui.setPasswordPage": "Setzen Sie Ihr Passwort",
    "user.ui.resetPasswordSuccess": "Ihr Passwort wurde zurückgesetzt, sie können sich jetzt einloggen",
    "user.ui.setPasswordSuccess": "Ihr Passwort wurde gesetzt, sie können sich jetzt einloggen",
    "user.ui.selectCustomerType": "Sie sind ... bitte auswählen",
    "user.ui.stayLoggedin": "Angemeldet bleiben",
    "user.ui.stayLoggedinInfo": "Sollte dies ein öffentlich zugängliches Gerät sein, deaktivieren Sie bitte diese Option und melden sich zum Abschluss wieder ab.",
    "user.ui.street": "Straße",
    "user.ui.salutation": "Anrede",
    "user.ui.title": "Titel",
    "user.ui.thankYouForSubscribing": "Vielen Dank, dass Sie unseren Newsletter abonniert haben.",
    "user.ui.errorAtSubscribing": "Fehler beim Abonnieren des Newsletters",
    "user.ui.unsubscribe": "abmelden",
    "user.ui.unsubscribePage": "Wollen Sie sich wirklich von unserem Newsletter abmelden?",
    "user.ui.uploadDrop": "Zum Hochladen ziehen Sie eine Datei hierher oder ",
    "user.ui.uploadSelectFile": "wählen Sie eine Datei aus",
    "user.ui.uploadToMyStorage": "Neue Bilder in mein Verzeichnis hochladen",
    "user.ui.uploadUserImage": "Benutzerbild auswählen",
    "user.ui.username": "E-Mail-Adresse",
    "user.ui.userName": "Benutzername",
    "user.ui.visibilityInfo": "Tipp: Was aus deinem Profil öffentlich angezeigt wird entscheidest du! Klicke auf das Auge-Symbol, um die Sichtbarkeit deiner Eingaben einzustellen:",
    "user.ui.zip": "Postleitzahl",
    "user.ui.phone": "Telefonnummer",
    "user.user": "Einträger",
    "user.users": "Einträgern",
    "widgets.shoppingCart.wishlist": "Merk- und Wunschliste",
    "widgets.shoppingCart.wishlist.empty": "Sie haben noch keine Produkte auf Ihrer Merk- und Wunschliste",
    "widgets.shoppingCart.cart": "Warenkorb",

    "user.product.addedToFavorites": "Der Artikel wurde zu Ihren Favoriten hinzugefügt",

    "order.status.zero": "Bestellung eingegangen",
    "order.status.one": "In Bearbeitung",
    "order.status.two": "Bestellung wurde versandt",
    "order.status.three": "Zugestellt",
    "order.status.four": "Retoure",
    "order.status.default": "Unbekannter Besetzungszustand",

    "country.germany": "Deutschland",
    "country.de": "Deutschland",
    "country.austria": "Österreich",
    "country.at": "Österreich",
    // EU
    "country.belgium": "Belgien",
    "country.denmark": "Dänemark",
    "country.bulgaria": "Bulgarien",
    "country.croatia": "Kroatien",
    "country.cyprus": "Zypern",
    "country.czechia": "Tschechien",
    "country.estonia": "Estland",
    "country.finland": "Finnland",
    "country.france": "Frankreich",
    "country.fr": "Frankreich",
    "country.greece": "Griechenland",
    "country.hungary": "Ungarn",
    "country.ireland": "Irland",
    "country.italy": "Italien",
    "country.it": "Italien",
    "country.latvia": "Lettland",
    "country.lithuania": "Litauen",
    "country.luxembourg": "Luxemburg",
    "country.lu": "Luxemburg",
    "country.malta": "Malta",
    "country.netherlands": "Niederlande",
    "country.poland": "Polen",
    "country.portugal": "Portugal",
    "country.romania": "Rumänien",
    "country.slovakia": "Slowakei",
    "country.slovenia": "Slowenien",
    "country.spain": "Spanien",
    "country.sweden": "Schweden",
    // other european
    "country.norway": "Norwegen",
    "country.switzerland": "Schweiz",
    "country.ch": "Schweiz",
    "country.turkey": "Türkei",
    "country.unitedKingdom": "Vereinigtes Königreich",
    "country.gb": "Großbritannien",

    "openingHours.tNowClosed": "Jetzt geschlossen",
    "openingHours.tOpeningHoursNotKnown": "weitere Öffnungszeiten nicht bekannt",
    "openingHours.tNowOpen": "Jetzt geöffnet",
    "openingHours.tNowStillOpen": "Jetzt noch geöffnet",
    "openingHours.tNextOpeningHourUnknown": "nächster Öffnungstermin unbekannt",
    "openingHours.tSoonOpenFrom": "bald wieder geöffnet ab",
    "openingHours.tFrom": "ab",
    "openingHours.tUntil": "bis",
    "openingHours.tOpenAgainFrom": "wieder geöffnet ab",
    "openingHours.tOpenAgainFrom2": "wieder geöffnet am",

    "comparison.fields.measurements": "Masse",
    "comparison.fields.weight": "Gewicht",

    // tag translations

    "application.tag.neu": "Neu",
    "application.tag.sale": "Abverkauf",
    "application.tag.eco": "Eco",
    "application.tag.sonderrabatt": "Sonderrabatt",
    "job.application.selectLocation": "Wähle deinen Standort aus",
    "job.application.location": "In folgenden Regionen verfügbar"
  };
});