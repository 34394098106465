define("webapp/locales/it/translations", ["exports", "webapp/locales/it/translations-base"], function (exports, _translationsBase) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var _extends = Object.assign || function (target) {
    for (var i = 1; i < arguments.length; i++) {
      var source = arguments[i];

      for (var key in source) {
        if (Object.prototype.hasOwnProperty.call(source, key)) {
          target[key] = source[key];
        }
      }
    }

    return target;
  };

  exports.default = _extends({}, _translationsBase.default, {
    "account.ui.entryBannerImageNew": "Imposta un'immagine principale per il tuo studio.",
    "application.ui.back": "indietro",
    "application.ui.confirmDeleteEntry": "Lo studio {{title}} cancellare irrevocabilmente?",
    "application.ui.entriesFocus": "Studi selezionati a fuoco:",
    "application.ui.entriesLatest": "Ultimi studi a fuoco:",
    "application.ui.goToEntry": "Chiama lo studio",
    "application.ui.newEntry": "Creare un nuovo studio",
    "application.ui.noEntry": "Non hai ancora creato uno studio. Crea subito il tuo primo studio...",
    "application.ui.noEntryProfile": "non ha ancora creato uno studio.",
    "application.ui.showAllEntries": "Mostra tutti gli studi",
    "application.widgets.newestEntries": "Gli studi più nuovi",
    "application.widgets.themeSwitch.active": "Modalità oscura attivata. Un grazie dall'ambiente!",
    "application.widgets.themeSwitch.default": "Risparmiate energia, attivate la modalità \"dark\".",
    "buttons.alreadyMeToo": "Ti piace questo studio.",
    "buttons.alreadyMeTooMobile": "Ti piace questo studio.",
    "entries.edit": "Studio <strong>{{title}}</strong> edit",
    "entries.entries": "Studi",
    "entries.entry.tooltip.alreadyCopied": "Questo studio ti piace già.",
    "entries.entry.tooltip.alreadyFollow": "Segui già questo studio",
    "entries.entry.tooltip.alreadyLiked": "Ti piace già questo studio",
    "entries.entry.tooltip.copied": "Sono contento che ti piaccia questo studio. La tua foto del profilo è stata aggiunta a questo studio.",
    "entries.entry.tooltip.follow": "Clicca qui per seguire questo studio",
    "entries.entry.tooltip.like": "Clicca qui per dare un Like a questo studio",
    "entries.entry.tooltip.noFollow": "Seguire va solo con gli studi stranieri",
    "entries.entry.tooltip.noFollowNotLoggedIn": "Devi essere loggato per seguire questo studio",
    "entries.entry.tooltip.noLike": "Come va solo con gli studi stranieri",
    "entries.entry.ui.access": "Questo studio può essere visto solo da",
    "entries.entry.ui.addEntry": "Aggiungere studi",
    "entries.entry.ui.allAccess": "Tutti possono vedere questo studio",
    "entries.entry.ui.categories": "Categorie per questo studio",
    "entries.entry.ui.commercialTitle": "Informazioni aziendali sullo studio",
    "entries.entry.ui.editEntry": "Modifica studio",
    "entries.entry.ui.editEntryNew": "Creare uno studio",
    "entries.entry.ui.isMainImageAndListImage": "Questa immagine appare come immagine principale dello studio",
    "entries.entry.ui.isMainImageInfo": "La sezione mostrata corrisponde approssimativamente al display desktop, mentre la cornice giallo corrisponde al display mobile.",
    "entries.entry.ui.noDescriptions": "Lo studio non ha ancora testi descrittivi.",
    "entries.entry.ui.noEntries": "Nessuno studio trovato.",
    "entries.entry.ui.onlyVisibleForCustomers": "Questo studio è visibile solo per i clienti registrati",
    "entries.entry.ui.overallProgress": "Questo studio è {{value}}% descritto",
    "entries.entry.ui.skillsNeeded": "Potrei usarlo nel mio studio...",
    "entries.entry.ui.title": "Lo studio appare sotto i seguenti nomi",
    "entries.entry.ui.titleError": "Lo studio deve essere nominato prima di salvare",
    "entries.entry.ui.visible": "Questo studio è visibile",
    "entries.open": "Chiamare lo studio nel frontend",
    "entries.person.ui.open": "Direttamente allo studio di questa persona",
    "entries.entry.ui.toDetail": "Diretto a",
    "entries.progress.title": "Come migliorare il tuo studio",
    "entries.tabbar.entry": "Dati dello studio",
    "entries.tabbar.tooltip.entryNotSavedDetail": "I dati dello studio non possono essere modificati finché lo studio con il titolo non è stato salvato.",
    "entries.tabbar.tooltip.entryNotSavedLayout": "I dati del layout possono essere modificati solo dopo che lo studio con titolo è stato salvato.",
    "entries.ui.groupDetailName": "Nome per le voci del gruppo",
    "entryTypes.entry": "Studio",
    "profile.entries.copycats": "Studi che {{userName}} vuole anche fare",
    "profile.entries.donates": "Studi che {{userName}} supporta",
    "profile.entries.entries": "Studi di {{userName}}",
    "profile.entries.follows": "Studios, che {{userName}} segue",
    "profile.entries.joins": "Studi dove {{userName}} partecipa",
    "profile.marketplace.offer.relatedEntry": "Studio assegnato",
    "social.hasLiked": "Questo studio ti piace già.",
    "user.company.copyContact": "Riprendere i dati di contatto dalle informazioni aziendali per lo studio",
    "user.ui.addedStudios": "sono disposti.",
    "user.ui.accountMenuEntries": "I miei studi",
    "user.ui.accountMenuEntry": "Studio",
    "user.ui.accountMenuEntryPre": "Studi,",
    "user.ui.accountMenuImages": "Il mio elenco",
    "user.ui.accountMenuFollows": "Studi che seguo",
    "user.ui.accountMenuLikes": "Studi che mi piacciono",
    "user.ui.accountMenuSupport": "Studi supportati",
    "user.ui.accountMenuSupports": "Studi che sostengo",
    "user.ui.documents": "Contentpool",
    "user.company.imprintFields": "Informazioni sull'impronta",
    "user.company.imprintCompany": "Fornitore del sito",
    "user.company.imprintCEO": "Amministratore delegato/proprietario",
    "user.company.imprintTaxId": "Codice fiscale",
    "user.company.imprintAddress": "Indirizzo",
    "user.company.imprintResponsible": "Responsabile del contenuto",
    "user.ui.ownDocuments": "Documenti per questo studio",
    "user.ui.ownVideos": "Video",
    "user.ui.user": "Utente",
    "user.ui.user.complete": "I tuoi dati utente sono completi.",
    "user.ui.user.incomplete": "I vostri dati utente potrebbero ancora essere completati. Se vuoi completare i tuoi dati, compila i seguenti campi:",
    "user.ui.user.verified": "Il tuo account utente è stato verificato e approvato.",
    "user.ui.salutation": "Saluto",
    "user.ui.welcome": "Benvenuti,",
    "user.ui.hasAdministrativeRights": "Hai i diritti amministrativi e puoi invitare altri utenti della tua azienda.",
    "entries.entry.ui.imagesInfo": "Le immagini possono essere modificate sotto \"My Directory\".",
    "user.ui.accountProgressbarEntry": "Il tuo studio è {{value}}% completo",
    "user.company.addUserShort": "Creare un nuovo utente",
    "user.ui.noCompany": "Nessuna azienda è stata ancora creata per il tuo account utente. Contatta la tua persona di riferimento.",
    "user.ui.companyNameTooltip": "Importante: il tuo studio apparirà allora anche sotto questo nome di società.",
    "user.ui.dataDelete": "altri studi. Vuoi cancellare il file in modo permanente? Ovunque questo file sia usato, sarà sostituito da un segnaposto.",
    "user.user": "Utente",
    "user.users": "Utente",
    "user.company.noEditingRights": "Non puoi ancora apportare modifiche perché non ti sono ancora stati assegnati i diritti necessari.",
    "search.searchBarPlaceholderEntries": "Cerca per nome o indirizzo dello studio.",
    "search.searchBarPlaceholderJobs": "Cerca i lavori o nella loro descrizione.",
    "search.searchBarPlaceholder.job": "Cerca un titolo di lavoro o nella sua descrizione e premi Invio...",
    "search.searchBarPlaceholderMobile.job": "Cerca il lavoro...",
    "search.searchBarPlaceholder.event": "Cerca il titolo di un evento o nella sua descrizione e poi premi Invio...",
    "search.searchBarPlaceholder.entry": "Cerca gli studi o nella loro descrizione e premi Invio...",
    "search.searchBarPlaceholderEvents": "Cerca per date o eventi.",
    "search.searchBarPlaceholderDefault": "Cerca...",
    "search.searchBarPlaceholderGeoSearch": "La tua posizione...",
    'search.searchBarPlaceholderArticles': "Cerca articoli nel titolo e nel testo",
    'search.searchBarPlaceholderArticlesMobile': "Cerca articoli",
    "search.searchTerm.pre": "Solo studi, filtrati da",
    "search.backToResults": "Torna alla lista dei risultati",
    "noResults": "Nessun risultato",
    "application.ui.flyout.receiver": "Contatta",
    "application.ui.flyout.bodystreet-trial": "Allenamento di prova",
    "application.ui.flyout.bodystreet-infopackage": "Pacchetto informativo",
    "application.ui.flyout.cart": "Negozio",
    'application.ui.flyout.bodystreet-studio': 'Studi',
    "application.ui.flyout.bodystreet-shop": "eStore",
    "application.ui.flyout.facebook": "Facebook",
    "application.ui.flyout.sing": "Xing",
    "application.ui.flyout.info": "Info",
    "application.ui.flyout.bodystreet-info": "Info",
    "application.ui.flyout.bodystreet-ems": "Allenamento di prova",
    "application.ui.flyout.bodystreet-voucher": "Coupon",
    "entries.entry.ui.externals": "Interfacce",
    "entries.entry.ui.tabStudio": "Studio",
    "entries.entry.ui.bookTrial": "Prenota ora un allenamento di prova nello studio",
    "entries.entry.ui.bookVoucher": "Assicurati ora il tuo voucher di formazione per",
    "entries.entry.ui.voucherTitle": "Il tuo voucher di allenamento personale per",
    "entries.entry.ui.voucherIntro": "Vuoi conoscere il rivoluzionario metodo Bodystreet senza impegno? Allora ottieni il tuo voucher di allenamento personale per un allenamento intensivo completo di Bodystreet con personal trainer e tutto ciò che ne consegue.",
    "entries.entry.ui.voucherInfo": "Ecco come funziona: Inserisci semplicemente il tuo nome, indirizzo e-mail e numero di telefono qui sopra e invia. Riceverai quindi il tuo voucher via e-mail e lo studio ti chiamerà per fissare un appuntamento. Si prega di non dimenticare di portare il voucher alla formazione. Si può riscattare solo 1 buono per persona.",
    "entries.entry.ui.goTrialSession": "Alla prenotazione dell'appuntamento",
    "entries.entry.ui.noTrial": "non offre un buono al momento. Vuoi invece contattarci direttamente?",
    "entries.entry.ui.trialSession": "Formazione di prova",
    "entries.entry.ui.facebookPixel": "Codice per il Pixel di Facebook sulla tua pagina di studio",
    "entries.entry.ui.magicline": "Il mio Magicline è impostato per visualizzare gli allenamenti di prova.",
    "entries.entry.ui.magiclineNoID": "Nessun ID viene memorizzato per il tuo Magicline. Non è possibile connettersi. Si prega di contattare la persona di riferimento.",
    "entries.entry.ui.globalCampaign": "Mostra il modulo del buono di formazione generale",
    "entries.entry.ui.provenExpertsID": "ID ProvenExperts",
    "entries.entry.ui.getDirections": "Navigare lì",
    "entries.entry.ui.team": "Il nostro team",
    "entries.entry.ui.jobs": "Posizioni aperte",
    "entries.entry.ui.noRights": "Questo campo non può essere cambiato.",
    "entries.entry.ui.articles": "Notizie interessanti",
    "entries.entry.ui.events": "Eventi",
    "entries.entry.ui.jobApply": "Applicare ora",
    "entries.entry.ui.jobAvailableAt": "È disponibile in questi luoghi",
    "entries.entry.ui.studio": "Il nostro studio",
    "entries.entry.ui.sharing": "Questa pagina su {{sharing}} azione",
    "entries.entry.ui.shareMail": "Condividi questa pagina per posta",
    'entries.entry.ui.imageType.standard': 'Standard (max. 2 immagini)',
    'entries.entry.ui.imageType.hero': 'Hero (immagine banner)',
    "entries.entry.ui.imageType.product": "Immagine dello studio",
    "entries.entry.ui.imageType.cover": "Immagine di copertina",
    "entries.entry.ui.imageType.team": "Immagine della persona o della squadra",
    "entries.entry.ui.imageType.award": "Premio o sigillo",
    "entries.entry.ui.imageType.action": "Immagine della campagna",
    "entries.entry.ui.imageType.testimonial": "Testimonianza",
    "entries.entry.ui.tabRelationsMobile": "Connessioni",
    "entries.entry.ui.tabImagesMobile": "Immagine/Video",
    "entries.entry.ui.closed": "chiuso",
    "entries.entry.ui.miscellaneousSettings": "Altre impostazioni",
    "entries.entry.ui.provenExpertsSettings": "ProvenExperts",
    "entries.entry.ui.globalPromotionSettings": "Voucher generale",
    "entries.entry.ui.globalPromotionPrice": "Testo e prezzo del voucher generale",
    "entries.entry.ui.facebookSettings": "Facebook",
    "entries.entry.ui.facebookTracking": "Monitoraggio di Facebook",
    "entries.entry.ui.calendly": "Componente URL di Calendly",
    "entries.entry.ui.studioSoftware": "Studio Software",
    "entries.entry.ui.trialBooking": "Scegliere una data per una sessione di allenamento di prova",
    "entries.entry.ui.socialPostOpen": "Aprire questo post",
    "entries.entry.ui.openingWeek": "Orari di apertura questa settimana",
    "job.application.title": "La sua applicazione",
    "job.application.thanks.salutation": "Caro richiedente,",
    "job.application.thanks.title": "Grazie per la sua candidatura e per il suo interesse a fare il prossimo passo di carriera con Bodystreet.",
    "job.application.thanks.content": "Nella fase successiva, elaboreremo i vostri documenti e poi ci metteremo in contatto con voi.",
    "job.application.thanks.contact": "Hai già qualche domanda importante? Allora potete contattarci direttamente.",
    "job.application.thanks.successWish": "Vi auguriamo ogni successo!",
    "job.application.studio": "Studio",
    "job.application.selectRegionCategory": "Seleziona una regione per la quale vuoi fare domanda",
    "job.application.selectStudio": "Seleziona almeno uno studio",
    "job.application.personalData": "I suoi dati personali",
    "job.application.documents": "Applicazione",
    "job.application.checkTransfer": "Acconsento all'inoltro della mia domanda ad altri studi Bodystreet della zona.",
    "job.application.privacyTitle": "Confermo di aver letto le \"Informazioni sulla protezione dei dati per i candidati\" e acconsento al trattamento dei miei dati personali (cliccare sulla casella appropriata).",
    "job.application.privacyInfo": "Qualsiasi ulteriore utilizzo dei dati e divulgazione dei miei dati personali a terzi non avrà luogo. Posso revocare questo consenso alla protezione dei dati in qualsiasi momento con effetto per il futuro.",
    "job.application.checkPrivacy": "memorizzati e utilizzati per contattarmi per iscritto, via e-mail o per telefono nell'ambito del processo di candidatura e anche per informarmi su altre offerte di lavoro di Bodystreet GmbH, Bodystreet Trainings GmbH o dei partner in franchising di Bodystreet;",
    "job.application.checkApplicationPool": "saranno conservati per un periodo massimo di 6 mesi nel contesto dell'inclusione nel pool di candidati di Bodystreet, trasmessi ai partner in franchising di Bodystreet durante questo periodo e conservati e utilizzati da questi ultimi per contattarmi per iscritto, via e-mail o per telefono come parte del processo di candidatura e anche per informarmi su altre offerte di lavoro nel sistema Bodystreet.",
    "job.application.submit": "Invia la domanda ora",
    "job.application.submitNotReady": "Tutti i campi obbligatori devono essere compilati per inviare il modulo.",
    "job.application.coverNote": "Lettera di presentazione",
    "job.application.cv": "Curriculum Vitae",
    "job.application.photo": "Immagine dell'applicazione",
    "job.application.certificate": "Testimonianze",
    "job.application.misc": "Altro",
    "job.application.message": "Nota",
    "job.application.reason": "Perché stai facendo domanda a Bodystreet?",
    "job.application.trainer": "Perché sei l'allenatore perfetto?",
    'job.fields.yourDuties': 'I vostri compiti',
    "job.fields.yourOpportunities": "Cosa si impara con noi",
    "job.fields.yourProfile": "Il tuo profilo",
    "job.fields.jobPartner": "I nostri partner per la formazione e lo studio",
    "job.fields.jobContent": "Le tue possibilità + vantaggi",
    "job.fields.aboutUs": "Cosa vi offriamo",
    "job.ui.noOffer": "Nessuna apertura per questo lavoro in questo momento.",
    "user.ui.phoneType": "Telefono",
    "user.ui.phoneTypeMobile": "Mobile",
    "user.ui.phoneTypeLandline": "Telefono",
    "user.ui.fullName": "Nome e cognome",
    "user.ui.dateWish": "Data desiderata",
    "user.ui.messageWish": "Auguri speciali",
    "trial.calendar.today": "Oggi",
    "trial.calendar.form.privacy": "Sì, i dati inviati saranno trattati allo scopo di gestire la mia richiesta e potranno essere utilizzati anche per contattarmi per telefono o per e-mail. Posso trovare maggiori informazioni nell'informativa sulla privacy.",
    "trial.calendar.form.yourTrailSession": "La tua formazione di prova su",
    "trial.calendar.form.selectTrailSession": "Per prima cosa scegli la data del tuo allenamento di prova.",
    "trial.calendar.form.info": "A Bodystreet sarai sempre supervisionato da un personal trainer durante il tuo allenamento di prova - e naturalmente anche dopo.",
    "trial.calendar.form.submit": "Fissa il tuo appuntamento ora",
    "trial.calendar.form.trialSessionBooked": "La sua richiesta è stata ricevuta. Dovresti ricevere a breve un'e-mail di conferma.",
    "menu.company.alternativeTitle": "Valori",
    "campaign.title": "Azioni attuali",
    "campaign.form.privacyInfo": "Acconsento al trattamento elettronico dei miei dati e alla loro archiviazione ai fini dell'elaborazione e della risposta alla mia richiesta. Questo consenso può essere revocato in qualsiasi momento per il futuro inviando un'e-mail al rispettivo studio.",
    "campaign.search.location": "Il tuo codice postale",
    "campaign.submit": "Invia",
    "campaign.selectLocation": "Basta specificare la posizione,",
    "campaign.selectedEntry": "Studio di prova selezionato:",
    'campaign.search.locationFull': 'Il vostro codice postale o città',
    "campaign.selectStudio": "Seleziona lo studio,",
    "campaign.start": "Iscriviti e andiamo!",
    "campaign.form.intro": "Vorrei lavorare {{product}} in studio  <span class='form-studio-title'>{{title}}</span> per il prezzo di {{productPrice}}.",
    "campaign.form.introNoPrice": "Vorrei lavorare {{product}} in studio <span class='form-studio-title'>{{title}}</span>.",
    "user.ui.uploadDrop": "Per caricare, trascinare un file qui o",
    "user.ui.uploadSelectFile": "selezionare un file",
    "entries.entry.ui.jobReplyMail": "Inviare le candidature al seguente indirizzo e-mail (se vuoto, la posta andrà all'indirizzo e-mail dello studio)",
    "entries.entry.ui.jobReplyMailInvalid": "Inserisci un indirizzo email valido di Bodystreet",
    "user.ui.newsletterEmail": "E-mail",
    "user.ui.newsletterEmailnotValid": "Inserisci il tuo indirizzo email",
    "user.ui.newsletterEmailValid": "Iscriviti ora!",
    "widget.gdpr.personalisation": "Contenuti personalizzati, misurazione delle prestazioni, sviluppo e miglioramento di offerte personalizzate",
    "widget.gdpr.advertising": "Misurazione dell'audience con memorizzazione e accesso alle informazioni su un dispositivo finale",
    "widget.gdpr.social": "Cookie dei social media"
  });
});